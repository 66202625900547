import { storeToRefs } from 'pinia'
import type { ErrorT, Test, Question } from '~/types'
import { useQuestionStore } from '~/stores/question'
import { useSnackbarStore } from '~/stores/snackbar'
import { useAuthStore } from '~/stores/auth'
import { useFilterStore } from '~/stores/filter'
import { useAPI } from '~~/composables/useAPI'

export const useTestStore = defineStore('testStore', () => {
  const { setSnackbar } = useSnackbarStore()
  const authStore = useAuthStore()
  const { headers } = storeToRefs(authStore)
  const { categoryFilters, questionTagFilters, testTagFilters, questionTypeFilters } = storeToRefs(useFilterStore())
  const { questionIDs } = storeToRefs(useQuestionStore())
  const config = useRuntimeConfig()
  const { showError } = useAPI()

  const tests = ref<Test[]>([{} as Test])
  const test = ref<Test>()
  const tempTest = ref<Test>()
  const testQuestions = ref<Question[]>([])
  const allTestQuestions = ref<Question[]>([])
  const questionOrder = ref<number[]>([])
  const tempQuestionOrder = ref<number[]>([])

  const isLoading = ref(false)
  const isPending = ref(false)
  const isPendingOutput = ref(false)
  const isValid = ref(true)
  const total = ref(0)
  const last_page = ref(0)
  const cacheBuster = ref(0)

  const selectedCategoryFilters = ref<{ [key: string]: number }>({})
  const selectedTagFilters = ref<{ [key: string]: number }>({})
  const selectedTestTagFilters = ref<{ [key: string]: number }>({})
  const selectedTypeFilters = ref<{ [key: string]: number }>({})

  const testSettings = ref({
    displayNarrativesOnce: true,
    keyLocation: true,
    keyRationale: true,
    keyReference: true,
  })
  const testPrintSettings = ref({
    answerKey: true,
    answerSlot: true,
    gradeKey: false,
    header: true,
    footer: true,
    nameDate: true,
  })
  const testPrintVersionSettings = ref({
    testVersions: 1,
    testVersion: 1,
    scrambleQuestions: false,
    scrambleAnswers: false,
  })

  const scrambleResponses = ref(false)
  const testNarratives = ref<{ [key: string]: any }>({})
  const testPrintVersionUpdate = ref(1)

  const parameters = ref({
    page: 1,
    per_page: 10,
    current_page: 1,
    order_direction: '',
    groupBy: [],
    order_by: 'title',
    search: '',
    watch: [cacheBuster.value],
  })
  const tempParameters = ref({ ...parameters })

  const parametersOutput = ref({
    page: 1,
    per_page: 10,
    order_direction: '',
    groupBy: [],
    order_by: 'title',
    search: '',
    watch: [cacheBuster.value],
  })

  const params = computed(() => {
    return { ...parameters.value }
  })
  const paramsCTTT = computed(() => {
    return { ...parameters.value, ...selectedCategoryFilters.value, ...selectedTagFilters.value, ...selectedTestTagFilters.value, ...selectedTypeFilters.value }
  })
  const paramsTT = computed(() => {
    return { ...parameters.value, ...selectedTagFilters.value, ...selectedTypeFilters.value }
  })
  const paramsAll = computed(() => {
    return { ...parametersOutput.value, ...selectedTagFilters.value, ...selectedTypeFilters.value }
  })

  const getTestQuestionTotal = computed(() => {
    return testQuestions.value.length
  })
  const getAllTestQuestionTotal = computed(() => {
    return allTestQuestions.value.length
  })

  const testQuestionIDs = computed(() => {
    return testQuestions.value.map((item) => item.id)
  })

  const allTestQuestionIDs = computed(() => {
    return allTestQuestions.value.map((item) => item.id)
  })

  interface TestsAPIData {
    data: Test[]
    total: number
  }

  const {
    data: testsData,
    error: testsError,
    execute: execTests,
  } = useFetch<TestsAPIData>(`${config.public.API_URL}/api/tests`, {
    method: 'GET',
    baseURL: config.public.API_URL,
    headers: headers,
    params: paramsCTTT,
    immediate: false,
    watch: false,
  })

  async function fetchTests() {
    if (isPending.value) return

    console.log(`fetchTests()`)
    isPending.value = true

    selectedCategoryFilters.value = {}
    selectedTagFilters.value = {}
    selectedTestTagFilters.value = {}
    selectedTypeFilters.value = {}

    if (categoryFilters.value.length) {
      categoryFilters.value.forEach((category: number, index: number) => {
        selectedCategoryFilters.value[`selected_categories[${index}]`] = category
      })
    }

    if (questionTagFilters.value.length) {
      questionTagFilters.value.forEach((tag: number, index: number) => {
        selectedTagFilters.value[`tags[${index}]`] = tag
      })
    }

    if (testTagFilters.value.length) {
      testTagFilters.value.forEach((tag: number, index: number) => {
        selectedTestTagFilters.value[`tags[${index}]`] = tag
      })
    }

    if (questionTypeFilters.value.length) {
      questionTypeFilters.value.forEach((type: number, index: number) => {
        selectedTypeFilters.value[`question_types[${index}]`] = type
      })
    }

    await execTests()

    if (testsError.value) {
      showError(testsError.value as ErrorT)
    } else if (testsData.value) {
      // console.log(`  tests = ${JSON.stringify(testsData.value.data)}`)
      tests.value = testsData.value.data
      total.value = testsData.value.total
    }
    isPending.value = false
  }

  const testID = ref(0)
  const testURL = computed(() => `${config.public.API_URL}/api/tests/${testID.value ? testID.value : 0}`)

  interface testAPIResponse {
    id: number
    title: string
    category: number
    theme_id: unknown
    header: unknown
    footer: unknown
    user_id: number
    created: string
    modified: string
    status: number
    notes: string
    questions: testAPIResponseQuestions
    tags: unknown[]
  }

  interface testAPIResponseQuestions {
    current_page: number
    data: testAPIResponseQuestionsData[]
    first_page_url: string
    from: number
    last_page: number
    last_page_url: string
    links: unknown[]
    next_page_url: string
    path: string
    per_page: string
    prev_page_url: unknown
    to: number
    total: number
  }

  interface testAPIResponseQuestionsData {
    id: number
    question_bank_id: number
    type_id: number
    explanation: unknown
    difficulty: unknown
    shuffle_answers: unknown
    answer_numbering: unknown
    reference: string
    notes: unknown
    created: string
    status: number
    main_branch: number
    parent_id: number
    iteration: number
    version_name: unknown
    history_json: string
    user_id: number
    general_feedback: string
    root_question_id: number
    narrative_id: unknown
    location: unknown
    objective: string
    stateStandard: unknown
    topic: unknown
    nationalObjective: string
    columns: number
    text: string
    original_date: string
    responses: Response[]
    tags: unknown[]
    narrative: unknown
  }

  const {
    data: testData,
    error: testError,
    execute: execTest,
  } = useFetch(
    () => {
      return testURL.value
    },
    {
      method: 'GET',
      baseURL: config.public.API_URL,
      headers: headers,
      params: paramsTT,
      immediate: false,
      watch: false,
    }
  )

  async function fetchTestById(id: number, getAll = false) {
    if (isPending.value) return

    console.log(`\nfetchTestById(${id})`)
    isPending.value = true
    testID.value = id

    // const tempPerPage = parameters.value.per_page

    if (getAll) {
      parameters.value.per_page = 10000
    }

    selectedTagFilters.value = {}
    selectedTypeFilters.value = {}

    if (testTagFilters.value.length) {
      testTagFilters.value.forEach((tag: number, index: number) => {
        // console.log(`  tags[${index}]`)
        selectedTagFilters.value[`tags[${index}]`] = tag
      })
    }

    if (questionTypeFilters.value.length) {
      questionTypeFilters.value.forEach((type: number, index: number) => {
        // console.log(`  question_types[${index}]`)
        selectedTypeFilters.value[`question_types[${index}]`] = type
      })
    }

    await execTest()

    if (testError.value) {
      showError(testError.value as ErrorT)
    } else if (testData.value) {
      test.value = testData.value
      tempTest.value = { ...test.value }
      // console.log(`  test = (${JSON.stringify(testData.value)})`)
      // console.log(`  testQuestions = (${JSON.stringify(testData.value.questions.data)})`)
      const testDataQuestions = testData.value as testAPIResponse
      console.log(`  testQuestions.length = (${testDataQuestions.questions.data.length})`)

      testQuestions.value = testDataQuestions.questions.data as unknown as Question[]
      total.value = testDataQuestions.questions.total
      last_page.value = testDataQuestions.questions.last_page
    }

    // parameters.value.per_page = tempPerPage
    isPending.value = false
  }

  const {
    data: testDataOutput,
    error: testErrorOutput,
    execute: execTestAll,
  } = useFetch(
    () => {
      return testURL.value
    },
    {
      method: 'GET',
      baseURL: config.public.API_URL,
      headers: headers,
      params: paramsAll,
      immediate: false,
      watch: false,
    }
  )

  async function fetchAllTestById(id: number) {
    if (isPendingOutput.value) return

    console.log(`fetchAllTestById(${id})`)
    isPendingOutput.value = true
    testID.value = id

    parametersOutput.value.per_page = 10000

    selectedTagFilters.value = {}
    selectedTypeFilters.value = {}

    await execTestAll()

    if (testErrorOutput.value) {
      showError(testErrorOutput.value as ErrorT)
    } else if (testDataOutput.value) {
      test.value = testDataOutput.value
      // console.log(`  test = (${JSON.stringify(testData.value)})`)
      // console.log(`  testQuestions = (${JSON.stringify(testData.value.questions.data)})`)

      const testDataQuestions = testDataOutput.value as testAPIResponse
      console.log(`  testQuestions.length = (${testDataQuestions.questions.data.length})`)
      allTestQuestions.value = testDataQuestions.questions.data as unknown as Question[]

      // allTestQuestions.value = testDataOutput.value.questions ? testDataOutput.value.questions.data : []

      questionOrder.value = allTestQuestions.value.map((question: Question) => question.id!)

      total.value = testDataQuestions.questions.total
      last_page.value = testDataQuestions.questions.last_page
    }
    isPendingOutput.value = false
  }

  const searchURL = computed(() => `${config.public.API_URL}/api/advanced_test_question_search/${testID.value ? testID.value : 0}`)
  const searchQuery = ref({})
  const searchBody = computed(() => searchQuery.value)

  const {
    data: searchData,
    error: searchError,
    execute: execSearch,
  } = useFetch(
    () => {
      return searchURL.value
    },
    {
      method: 'POST',
      baseURL: config.public.API_URL,
      headers: headers,
      params: paramsTT,
      body: searchBody,
      immediate: false,
      watch: false,
    }
  )

  async function searchTestById(id: number, queries: object) {
    if (isPending.value) return

    console.log(`searchTestById(${id})`)
    isPending.value = true
    parameters.value.per_page = 10000
    testID.value = id
    searchQuery.value = { advanced_options: queries }

    selectedTagFilters.value = {}
    selectedTypeFilters.value = {}

    if (testTagFilters.value.length) {
      testTagFilters.value.forEach((tag: number, index: number) => {
        selectedTagFilters.value[`tags[${index}]`] = tag
      })
    }

    if (questionTypeFilters.value.length) {
      questionTypeFilters.value.forEach((type: number, index: number) => {
        selectedTypeFilters.value[`question_types[${index}]`] = type
      })
    }

    await execSearch()

    if (searchError.value) {
      showError(searchError.value as ErrorT)
    } else if (searchData.value) {
      test.value = searchData.value
      // console.log(`  test = (${JSON.stringify(searchData.value)})`)
      // console.log(`  testQuestions = (${JSON.stringify(searchData.value.questions.data)})`)
      console.log(`  testQuestions.length = (${(searchData.value as { questions: any }).questions.data.length})`)
      testQuestions.value = (searchData.value as { questions: any }).questions.data
    }
    isPending.value = false
  }
//   return (searchData.value as { questions: any{} }).questions

  const {
    data: createData,
    error: createError,
    execute: execCreate,
  } = useFetch(`${config.public.API_URL}/api/tests`, {
    method: 'POST',
    baseURL: config.public.API_URL,
    headers: headers,
    body: test,
    immediate: false,
    watch: false,
  })

  async function createTest(isDuplicate = false) {
    console.log(`createTest(${isDuplicate})`)
    isPending.value = true

    if (test.value && isDuplicate) {
      test.value.title = test.value.title + ' - COPY'
    }

    await execCreate()

    if (createError.value) {
      if (createError.value.data.message === 'Duplicate Entry') {
        setSnackbar({
          type: `error`,
          text: `Test Already Exists`,
        })
      } else {
        showError(createError.value as ErrorT)
      }
      isPending.value = false

      return false
    } else if (createData.value) {
      setSnackbar({
        type: `success`,
        text: `New Test (${test.value!.title}) successfully created!`,
      })
      isPending.value = false
      // console.log(`Test Created! - ${JSON.stringify(dacreateData.valueta)}`)
      navigateTo({ path: `/tests/${(createData.value as { message: string }).message}/edit` })

      return (createData.value as { message: string }).message
    }
  }

  const {
    data: updateData,
    error: updateError,
    execute: execUpdate,
  } = useFetch(
    () => {
      return testURL.value
    },
    {
      method: 'PUT',
      baseURL: config.public.API_URL,
      headers: headers,
      body: test,
      immediate: false,
      watch: false,
    }
  )

  async function updateTest(routeID: number) {
    isPending.value = true
    console.log(`\nupdateTest()`)
    // console.log(` test = ${JSON.stringify(test.value)}`)

    testID.value = routeID

    await execUpdate()

    if (updateError.value) {
      if (updateError.value.data.message === 'Duplicate Entry') {
        setSnackbar({
          type: `error`,
          text: `Test Already Exists`,
        })
      } else {
        showError(updateError.value as ErrorT)
      }
      isPending.value = false

      return false
    } else if (updateData.value) {
      setSnackbar({
        type: `success`,
        text: `Test updated successfully!`,
      })
      // console.log(`Test updated! - ${JSON.stringify(updateData.value)}`)
      // router.push({ path: `/tests/${updateData.value.message}/edit` })
      tempTest.value = { ...test.value }

      fetchTests()

      if (questionIDs.value.length >= 0) await updateTestQuestions(routeID)
    }
    isPending.value = false
  }

  const updateTestURL = computed(() => `${config.public.API_URL}/api/tests/update_questions/${testID.value ? testID.value : 0}`)
  const testQuestionsBody = computed(() => ({
    question_list: [...questionIDs.value],
    scramble_responses: scrambleResponses.value,
  }))

  const {
    data: updateTestData,
    error: updateTestError,
    execute: execUpdateTest,
  } = useFetch(
    () => {
      return updateTestURL.value
    },
    {
      method: 'POST',
      baseURL: config.public.API_URL,
      headers: headers,
      body: testQuestionsBody,
      immediate: false,
      watch: false,
    }
  )

  async function updateTestQuestions(routeID: number) {
    console.log(`\nupdateTestQuestions(${routeID}): length = ${questionIDs.value.length}`)
    isPending.value = true
    testID.value = routeID

    await execUpdateTest()

    if (updateTestError.value) {
      showError(updateTestError.value as ErrorT)
    } else if (updateTestData.value) {
      setSnackbar({
        type: `success`,
        text: `Test questions updated successfully!`,
      })
      tempTest.value = { ...test.value }
    }
    isPending.value = false
  }

  const deletedID = ref(0)
  const deletedURL = computed(() => `${config.public.API_URL}/api/tests/${deletedID.value ? deletedID.value : 0}`)

  const {
    data: deletedData,
    error: deletedError,
    execute: execDeleted,
  } = useFetch(
    () => {
      return deletedURL.value
    },
    {
      method: 'delete',
      baseURL: config.public.API_URL,
      headers: headers,
      immediate: false,
      watch: false,
    }
  )

  async function deleteTest(id: number) {
    console.log(`deleteTest(${id})`)
    isPending.value = true
    deletedID.value = id

    await execDeleted()

    if (deletedError.value) {
      showError(deletedError.value as ErrorT)
    } else if (deletedData.value) {
      setSnackbar({
        type: `success`,
        text: 'Test successfully deleted.',
      })
      console.log(`Test deleted!`)
    }
    isPending.value = false
  }

  function resetTest() {
    // console.log(`resetTest()`)
    isPending.value = false
    isValid.value = false
    test.value = {} as Test
  }

  function $reset() {
    tests.value = <Array<Test>>[]
    test.value = {} as Test
    testQuestions.value = []
    isLoading.value = false
    isPending.value = false
    isValid.value = true
    total.value = 0
    cacheBuster.value = 0
    tempTest.value = {} as Test
    resetTest()
  }

  return {
    tests,
    test,
    testQuestions,
    allTestQuestions,
    questionOrder,
    tempQuestionOrder,
    getTestQuestionTotal,
    getAllTestQuestionTotal,
    isLoading,
    isPending,
    isPendingOutput,
    isValid,
    total,
    last_page,
    cacheBuster,
    parameters,
    tempParameters,
    parametersOutput,
    tempTest,
    testQuestionIDs,
    allTestQuestionIDs,
    params,
    testSettings,
    testPrintSettings,
    testPrintVersionSettings,
    testNarratives,
    testPrintVersionUpdate,
    scrambleResponses,
    fetchTests,
    fetchTestById,
    fetchAllTestById,
    searchTestById,
    createTest,
    updateTest,
    updateTestQuestions,
    deleteTest,
    resetTest,
    $reset,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTestStore, import.meta.hot))
}
