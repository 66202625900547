import { storeToRefs } from 'pinia'
import type { ErrorT, Tag } from '~/types'
import { useSnackbarStore } from '~/stores/snackbar'
import { useAuthStore } from '~/stores/auth'
import { useAPI } from '~~/composables/useAPI'

export const useTagStore = defineStore('tagStore', () => {
   const { setSnackbar } = useSnackbarStore()
   const { headers } = storeToRefs(useAuthStore())
   const config = useRuntimeConfig()
   const { showError } = useAPI()

   const tags = ref(<Array<Tag>>[])
   const tag = ref(<Tag>{} as Tag)
   const tempTag = ref(<Tag>{} as Tag)
   const isPending = ref(false)
   const error = ref(null)
   const isValid = ref(true)
   const total = ref(0)
   const cacheBuster = ref(0)
   const parameters = ref({
      page: 1,
      per_page: 10,
      order_direction: '',
      groupBy: [],
      order_by: 'description',
      search: '',
      watch: [cacheBuster.value]
   })
   const params = computed(() => {
      return { ...parameters.value }
   })
   const paramsAll = computed(() => {
      return { ...parameters.value, per_page: 10000 }
   })

   const { data: allTagsData, error: allTagsError, execute: execAllTags } = useFetch<{ data: Array<Tag>, total: number }>(`${config.public.API_URL}/api/tags`, {
      method: 'GET',
      baseURL: config.public.API_URL,
      headers: headers,
      params: paramsAll,
      immediate: false,
      watch: false,
   })

   async function fetchAllTags() {
      console.log(`fetchAllTags()`)
      isPending.value = true

      await execAllTags()

      if (allTagsError.value) {
         showError(allTagsError.value as ErrorT)
      } else if (allTagsData.value) {
         // console.log(`  tags = ${JSON.stringify(allTagsData.value)}`)
         tags.value = allTagsData.value.data
         total.value = allTagsData.value.total
      }
      isPending.value = false
   }

   const { data: tagsData, error: tagsError, execute: execTags } = useFetch<{ data: Array<Tag>, total: number }>(`${config.public.API_URL}/api/tags`, {
      method: 'GET',
      baseURL: config.public.API_URL,
      headers: headers,
      params: params,
      immediate: false,
      watch: false,
   })

   async function fetchTags() {
      isPending.value = true

      await execTags()

      if (tagsError.value) {
         showError(tagsError.value as ErrorT)
      } else if (tagsData.value) {
         // console.log(`fetchTags() = ${JSON.stringify(data)}`)
         tags.value = tagsData.value.data
         total.value = tagsData.value.total
      }
      isPending.value = false
   }

   async function fetchTag(tagId: number) {
      // console.log(`fetchTag(${tagId})`)
      isPending.value = true

      const tempTag = tags.value.find((tag) => tag.id === tagId)
      if (tempTag) {
         tag.value = tempTag
         isPending.value = false
      }
   }

   async function setTagById(tagId: number) {
      const tempTag = await tags.value.find((tag) => tag.id === tagId)
      if (tempTag) {
         tag.value = tempTag
      }
      // console.log(`setTagById(${tagId}): tag = ${JSON.stringify(tag.value)}`)
   }

   const { data: saveData, error: saveError, execute: saveCreate } = useFetch<{ message: string }>(`${config.public.API_URL}/api/tags`, {
      method: 'POST',
      baseURL: config.public.API_URL,
      headers: headers,
      body: tempTag,
      immediate: false,
      watch: false,
   })

   async function saveTag() {
      // console.log(`saveTag(): tempTag = ${JSON.stringify(tempTag.value)}`)
      isPending.value = true

      await saveCreate()

      if (saveError.value) {
         if (saveError.value.data.message === 'Duplicate Entry') {
            setSnackbar({
               type: `error`,
               text: `Tag Already Exists`,
            })
         } else {
            showError(saveError.value as ErrorT)
         }
         isPending.value = false
         return 0
      } else if (saveData.value) {
         setSnackbar({
            type: `success`,
            text: `Tag saved successfully!`,
         })
         console.log(`Tag [${saveData.value.message}] saved`)
         cacheBuster.value += 1
         await fetchTags()
         isPending.value = false

         return saveData.value.message
      }
   }

   const updateURL = computed(() => `${config.public.API_URL}/api/tags/${tempTag.value ? tempTag.value.id : 0}`)

   const { data: updateData, error: updateError, execute: execUpdate } = useFetch(() => { return updateURL.value }, {
      method: 'PUT',
      baseURL: config.public.API_URL,
      headers: headers,
      body: tempTag,
      immediate: false,
      watch: false,
   })

   async function updateTag() {
      // console.log(`updateTag()`)
      // console.log(`tempTag = ${JSON.stringify(tempTag.value)}`)
      isPending.value = true

      await execUpdate()

      if (updateError.value) {
         if (updateError.value.data.message === 'Duplicate Entry') {
            setSnackbar({
               type: `error`,
               text: `Tag Already Exists`,
            })
         } else {
            showError(updateError.value as ErrorT)
         }
      } else if (updateData.value) {
         setSnackbar({
            type: `success`,
            text: `Tag updated successfully!`,
         })
         cacheBuster.value += 1
      }
      isPending.value = false
   }

   const deleteID = ref(0)
   const deleteURL = computed(() => `${config.public.API_URL}/api/tags/${deleteID.value ? deleteID.value : 0}`)
   const { data: deleteData, error: deleteError, execute: execDelete } = useFetch(() => { return deleteURL.value }, {
      method: 'delete',
      baseURL: config.public.API_URL,
      headers: headers,
      immediate: false,
      watch: false,
   })

   async function deleteTag(id: number) {
      // console.log(`deleteTag(${id})`)
      isPending.value = true
      deleteID.value = id

      await execDelete()

      if (deleteError.value) {
         showError(deleteError.value as ErrorT)
      } else if (deleteData.value) {
         setSnackbar({
            type: `success`,
            text: 'Tag successfully deleted.',
         })
      }
      isPending.value = false
   }

   function $reset() {
      tags.value = <Array<Tag>>[]
      tag.value = <Tag>{} as Tag
      tempTag.value = <Tag>{} as Tag
      isPending.value = false
      error.value = null
      isValid.value = true
      total.value = 0
      cacheBuster.value = 0
   }

   return {
      tags, tag, tempTag, isPending, error, total, cacheBuster, parameters, params, fetchAllTags, fetchTags, fetchTag, setTagById, saveTag, updateTag, deleteTag, $reset
   }

})

if (import.meta.hot) {
   import.meta.hot.accept(acceptHMRUpdate(useTagStore, import.meta.hot))
}
