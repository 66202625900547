/* eslint-disable vue/valid-v-else */
<script setup>
import { useAppStore } from '~/stores/app'
import logo from '~/assets/images/2024-IEC-Logo-White.svg?url'
import logoColor from '~/assets/images/2024-IEC-Logo-Color.svg?url'
// import logoFull from '~/assets/images/IEC_Logo_full.svg?url'
import { storeToRefs } from 'pinia'

defineProps({ appbar: { type: Boolean, required: false, default: false }, fullcolor: { type: Boolean, required: false, default: false } })

const appStore = useAppStore()
const { isMobile } = storeToRefs(appStore)
</script>

<template>
  <!-- Appbar -->
  <div class="mx-auto" :class="[appbar ? 'py-2 logo-appbar' : 'logo', isMobile ? 'logo-small' : 'logo']">
    <img :src="fullcolor ? logoColor : logo" />
  </div>
</template>

<style scoped>
.logo {
  margin: 0 auto;
  position: relative;
}

.logo-appbar {
  height: 50px;
  margin: 0 auto;
  position: relative;
  text-align: left;
}
.logo-appbar.logo-small {
  height: 50px;
}
.logo-appbar img {
  height: 100%;
}
</style>
