import { storeToRefs } from 'pinia'
import type { ErrorT, Narrative } from '~/types'
import { useSnackbarStore } from '~/stores/snackbar'
import { useAuthStore } from '~/stores/auth'
import { useAPI } from '~~/composables/useAPI'

export const useNarrativeStore = defineStore('narrativeStore', () => {
  const { setSnackbar } = useSnackbarStore()
  const { headers } = storeToRefs(useAuthStore())
  const config = useRuntimeConfig()
  const questionStore = useQuestionStore()
  const { tempQuestion } = storeToRefs(questionStore)
  const { showError } = useAPI()

  const narratives = ref<Narrative[]>([])
  const narrative = ref<Narrative | null>()
  const narrativeBankID = ref(0)

  const tempNarrative = ref<Narrative | null>()
  const tempNarrativeID = ref(0)
  const isLoading = ref(false)
  const isValid = ref(true)
  const isPending = ref(false)
  const error = ref(null)
  const total = ref(0)
  const cacheBuster = ref(0)
  const parameters = ref({
    page: 1,
    per_page: 10,
    order_direction: '',
    groupBy: [],
    order_by: 'title',
    search: '',
    question_bank_id: 0,
    watch: [cacheBuster.value],
  })
  const params = computed(() => {
    return { ...parameters.value }
  })

  const getNarrativeById = computed(() => {
    // console.log(`getNarrativeById()`)
    return (narrativeId: number) => narratives.value.find((narrative) => narrative.id === narrativeId)
  })

  const {
    data: narrativesData,
    error: narrativesError,
    execute: execNarratives,
  } = useFetch<{ total: number; data: Narrative[] }>(`${config.public.API_URL}/api/narratives`, {
    method: 'GET',
    baseURL: config.public.API_URL,
    headers: headers,
    params: params,
    immediate: false,
    watch: false,
  })

  async function fetchNarratives(numPerPage?: number) {
    isPending.value = true

    if (narrativeBankID.value) parameters.value.question_bank_id = narrativeBankID.value
    if (numPerPage) {
      parameters.value.per_page = numPerPage
    }

    await execNarratives()

    if (narrativesError.value) {
      showError(narrativesError.value as ErrorT)
    } else if (narrativesData.value) {
      console.log(`fetchNarratives()`)
      total.value = narrativesData.value.total
      narratives.value = narrativesData.value.data
    }
    isPending.value = false
  }

  const narrativeID = ref(0)
  const narrativeURL = computed(() => `${config.public.API_URL}/api/narratives/${narrativeID.value ? narrativeID.value : 0}`)

  const {
    data: narrativeData,
    error: narrativeError,
    execute: execNarrative,
  } = useFetch(
    () => {
      return narrativeURL.value
    },
    {
      method: 'GET',
      baseURL: config.public.API_URL,
      headers: headers,
      immediate: false,
      watch: false,
    }
  )

  async function fetchNarrative(narrativeId: number) {
    // console.log(`fetchNarrative(${narrativeId})`)
    isPending.value = true
    narrativeID.value = narrativeId

    await execNarrative()

    if (narrativeError.value) {
      showError(narrativeError.value as ErrorT)
    } else if (narrativeData.value) {
      // console.log(`fetchNarrative() = ${JSON.stringify(narrativeData.value)}`)
    }
    isPending.value = false
  }

  const {
    data: createData,
    error: createError,
    execute: execCreate,
  } = useFetch(`${config.public.API_URL}/api/narratives`, {
    method: 'POST',
    baseURL: config.public.API_URL,
    headers: headers,
    body: narrative,
    immediate: false,
    watch: false,
  })

  async function createNarrative() {
    isPending.value = true
    // console.log(`createNarrative(): headers = ${JSON.stringify(headers.value)}`)

    await execCreate()

    if (createError.value) {
      showError(createError.value as ErrorT)
    } else if (createData.value) {
      cacheBuster.value += 1
      await fetchNarratives()

      setSnackbar({
        type: `success`,
        text: `New Narrative successfully created!`,
      })
    }
    isPending.value = false
  }

  const {
    data: updateData,
    error: updateError,
    execute: execUpdate,
  } = useFetch(
    () => {
      return narrativeURL.value
    },
    {
      method: 'PUT',
      baseURL: config.public.API_URL,
      headers: headers,
      body: narrative,
      immediate: false,
      watch: false,
    }
  )

  async function updateNarrative() {
    isPending.value = true

    if (narrative.value) narrativeID.value = narrative.value.id!

    console.log(`\n updateNarrative()`)

    if (!narrative.value) return

    await execUpdate()

    if (updateError.value) {
      showError(updateError.value as ErrorT)
    } else if (updateData.value) {
      console.log(`  updateData = ${JSON.stringify(updateData.value)}`)
      // console.log(`  ${JSON.stringify(updateData.value)}`)
      cacheBuster.value += 1
      await fetchNarratives()

      setSnackbar({
        type: `success`,
        text: `Narrative updated successfully`,
      })

      isPending.value = false
      return (updateData.value as { message: string }).message
    }
    isPending.value = false
  }

  const {
    data: deleteData,
    error: deleteError,
    execute: execDelete,
  } = useFetch(
    () => {
      return narrativeURL.value
    },
    {
      method: 'delete',
      baseURL: config.public.API_URL,
      headers: headers,
      immediate: false,
      watch: false,
    }
  )

  async function deleteNarrative(id: number) {
    console.log(`deleteNarrative(${id})`)
    narrativeID.value = id

    await execDelete()

    if (deleteError.value) {
      showError(deleteError.value as ErrorT)
    } else if (deleteData.value) {
      setSnackbar({
        type: `success`,
        text: 'Narrative successfully deleted.',
      })
      // console.log(`Narrative deleted!`)
    }
    isPending.value = false
  }

  function insertNarrative(item: Narrative) {
    // console.log(`insertNarrative(${item})`)
    narrative.value = { ...item }
    if (tempQuestion.value) {
      tempQuestion.value.narrative_id = narrative.value.id
      tempQuestion.value.narrative = item
    }

    setSnackbar({
      type: `success`,
      text: `Narrative successfully added!`,
    })
  }

  function setNarrativeBankID(id: number) {
    // console.log(`setNarrativeBankID(${id})`)
    narrativeBankID.value = id
  }

  function $reset() {
    narratives.value = []
    narrative.value = {} as Narrative
    tempNarrative.value = {} as Narrative
    narrativeBankID.value = 0
    isLoading.value = false
    isValid.value = true
    isPending.value = false
    error.value = null
    total.value = 0
    cacheBuster.value = 0
  }

  return {
    narratives,
    narrative,
    tempNarrative,
    tempNarrativeID,
    narrativeBankID,
    isLoading,
    isValid,
    isPending,
    error,
    total,
    cacheBuster,
    parameters,
    getNarrativeById,
    fetchNarratives,
    fetchNarrative,
    createNarrative,
    updateNarrative,
    deleteNarrative,
    insertNarrative,
    setNarrativeBankID,
    $reset,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNarrativeStore, import.meta.hot))
}
