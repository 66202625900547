import { useDisplay, useTheme } from 'vuetify'
import { useDateFormat } from '@vueuse/core'

export const useAppStore = defineStore('appStore', () => {
   const router = useRouter()

   const siteName = ref('Test Generator')
   const siteNameShort = ref('TBS')
   const drawer = ref(false)
   const drawerRail = ref(true)
   const helpDrawer = ref(false)
   const searchDrawer = ref(false)
   const settingsDrawer = ref(false)
   const rightDrawer = ref(false)
   const profileMenu = ref(false)
   const notificationsDrawer = ref(false)
   const fullScreen = ref(false)
   const hydrated = ref(false)
   const hydrating = ref(false)
   const error = ref(null)
   const dialog = ref(false)
   const dialogWidth = ref('900px')
   const infoDialog = ref(false)
   const isLoading = ref(false)
   const isSaved = ref(false)
   const isValid = ref(true)
   const menuItem = ref(-1)
   const miniAppbar = ref(false)
   const miniVariant = ref(false)
   const search = ref('')
   const page = ref(1)
   const tabs = ref('help')
   const pageWidth = ref(1024)
   const dateFormat = ref('YYYY-MM-DD h:mm A')
   const display = useDisplay()

   const sidebarOpen = computed(() => { window.innerWidth >= 1430 })
   const isMobile = computed(() => {
      return display.mobile.value
   })
   const isSmAndDown = computed(() => {
      return display.smAndDown.value
   })
   const isMdAndUp = computed(() => {
      return display.mdAndUp.value
   })
   const isMdAndDown = computed(() => {
      return display.mdAndDown.value
   })

   function resetApp() {
      // console.log(`resetApp()`)
      profileMenu.value = false
   }

   function toggleDrawer(value: boolean) {
      // console.log(`toggleDrawer(${value})`)
      if (value) {
         drawer.value = value
         console.log(value)
      } else {
         drawer.value = !drawer.value
         console.log(`drawer = ${drawer.value}`)
      }
   }

   function toggleRightDrawer(value: boolean) {
      // console.log(`toggleRightDrawer(${value})`)
      if (value) {
         rightDrawer.value = value
      } else {
         rightDrawer.value = !rightDrawer.value
      }
   }

   function toggleSearchDrawer(value: boolean) {
      // console.log(`toggleSearchDrawer(${value})`)
      if (value) {
         searchDrawer.value = value
      } else {
         searchDrawer.value = !searchDrawer.value
      }
   }

   function toggleRightDrawers(drawer: string) {
      // console.log(`toggleRightDrawers(${drawer})`)
      switch (drawer) {
         case 'notifications':
            notificationsDrawer.value = !notificationsDrawer.value
            settingsDrawer.value = false
            helpDrawer.value = false
            router.push({ path: `/notifications` })
            break
         case 'help':
            helpDrawer.value = !helpDrawer.value
            settingsDrawer.value = false
            break
         case 'settings':
            settingsDrawer.value = !settingsDrawer.value
            helpDrawer.value = false
            break
         default:
            break
      }
   }

   function formattedDate(newDate: Date | string, format?: string) {
      if (typeof format === "undefined") format = dateFormat.value
      const tempDate: string = useDateFormat(newDate, format).value.toString()

      return tempDate.replace(/^"/, '').replace(/"$/, '')
   }

   function $reset() {
      drawer.value = false
      drawerRail.value = true
      helpDrawer.value = false
      searchDrawer.value = false
      settingsDrawer.value = false
      rightDrawer.value = false
      profileMenu.value = false
      fullScreen.value = false
      hydrated.value = false
      hydrating.value = false
      error.value = null
      dialog.value = false
      dialogWidth.value = '900px'
      infoDialog.value = false
      isLoading.value = false
      isSaved.value = false
      isValid.value = true
      menuItem.value = -1
      miniAppbar.value = false
      miniVariant.value = false
      search.value = ''
      page.value = 1
      pageWidth.value = 1024
      dateFormat.value = 'YYYY-MM-DD hh:mm A'
   }

   return { siteName, siteNameShort, drawer, drawerRail, helpDrawer, settingsDrawer, searchDrawer, rightDrawer, profileMenu, notificationsDrawer, fullScreen, hydrated, hydrating, error, dialog, dialogWidth, infoDialog, isLoading, isSaved, isValid, menuItem, miniAppbar, miniVariant, search, page, tabs, pageWidth, dateFormat, display, sidebarOpen, isMobile, isSmAndDown, isMdAndUp, isMdAndDown, resetApp, toggleDrawer, toggleSearchDrawer, toggleRightDrawer, toggleRightDrawers, formattedDate, $reset }
})

if (import.meta.hot) {
   import.meta.hot.accept(acceptHMRUpdate(useAppStore, import.meta.hot))
}
