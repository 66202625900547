
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexHYEe7k9ORx6bvJSfB8wp_45e3vvZa70GcK2zz2zL5rW2kMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/account/index.vue?macro=true";
import { default as indexgXSpUHY32KTjDX5fbdJYQr1sziLmk1hDOHESECEW_45SEMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/categories/index.vue?macro=true";
import { default as index7lxz1bgx8ONqiqb8LEO63B5gtUtJfcMfYdYhJqHGHjYMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/chapters/index.vue?macro=true";
import { default as indexX0D1EmwSwA9rrxrg1sWUcEj2F62AlCFhHhdlXjdsgAIMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/cleanup/index.vue?macro=true";
import { default as index9EjCqR853zaLgX01ZcNmb06QlfZvLLGPMWcp8a_45NPOwMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/index.vue?macro=true";
import { default as indexF3ecmobjSdIcQtApncWmVjLjvTsDikm6aN1d2emH0SsMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/login/index.vue?macro=true";
import { default as indexsQVCK6o94EMkK6_45W4ptxyf7q1bV5lNclyIjc3aCz49AMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/notifications/[id]/index.vue?macro=true";
import { default as index6BaUd3AiC051xfn4e7wY3YVhUU5s9_45LZcKz6lROIKmUMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/notifications/index.vue?macro=true";
import { default as deletedbubsCaZV3TcsMVjhSNFe9_45qwvlLk5ecceF_45TEbKarUoMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/question-banks/[id]/deleted.vue?macro=true";
import { default as indexQQbGblQfygCT73nBjEdO7zGGvcgNpTRlbXhFOuMDyI4Meta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/question-banks/[id]/edit/index.vue?macro=true";
import { default as duplicate6s_457cITf1rY5qWEm_81ymphY9r60pfb27baUahZJGuAMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/question-banks/[id]/edit/qn/[qnid]/duplicate.vue?macro=true";
import { default as editN31sBl7OKbWLd9Dw4CyAAISKba7mK2v3dtsnqRCsYxkMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/question-banks/[id]/edit/qn/[qnid]/edit.vue?macro=true";
import { default as newm_45KbzVdr8rNp99mVxQ2iHbOt4Z1XfIv1l4ZmLVKPhkEMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/question-banks/[id]/edit/qn/new.vue?macro=true";
import { default as index8Vi1sN1DqVIKgpPU9Rl_Vfj4T7Vwo0Xh_0t4kft_EwIMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/question-banks/[id]/index.vue?macro=true";
import { default as indexFGoH14NKrcD8XrafKdILXFQQTjI8Da4n0oP_AMf9kksMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/question-banks/[id]/narratives/index.vue?macro=true";
import { default as indexY33DT5Fvo5N60wGK7IC6WuTA_C9CxUIvSUfIcvkpRNsMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/question-banks/index.vue?macro=true";
import { default as new1D8kLGikmRi4HFaYjjpiWn8KAhq_vWt2rjpKMV3NwaYMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/question-banks/new.vue?macro=true";
import { default as indexzstVEwUZrGZWtqe5Lc5Lo_45zpt8IQHPLc6KPyXW7JjagMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/question-banks/search/index.vue?macro=true";
import { default as editIqBOFpQdfKAIwmBbs90rYUKRm_Y_45K0OgaNrLh9Y8lCYMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/reports/[id]/edit.vue?macro=true";
import { default as index_45gzonjzWKGp4XqT6kTm8b3M_45BMvq_452XiNgAhRAGOqdUMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/reports/[id]/index.vue?macro=true";
import { default as indexGT3GcU_45qj3vC8GShc2i_458GKY608_sf_45zaWl2v0QOpxoMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/reports/index.vue?macro=true";
import { default as newuxXPy2PdUg2h7dDlanddx4wSE0NzXv5uNLtN3AHtS8YMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/reports/new.vue?macro=true";
import { default as indexCnEee1xWSxU9WR_LT9DwEkx7FxiqXK7jgvKJAgPA7D4Meta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/reset/index.vue?macro=true";
import { default as indexxlGQvGVhK_OiTgFomDrtTK5ukvp3G_45kwIALvJHSu4_EMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/support/index.vue?macro=true";
import { default as indexBTlGHG0oLFrW81dtBdVgtu6CWl3_45huddQ2tLz6lMxpIMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/tags/index.vue?macro=true";
import { default as indexnl62lcqsaknW0YJq1uFTrmnC1AiMRA6Yf5be_iq4adcMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/tests/[id]/edit/index.vue?macro=true";
import { default as duplicate_45xnWMsIj074fOj8Zrc4NlDa8JraCOOaUIVu8t4hD1qcMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/tests/[id]/edit/qn/[qnid]/duplicate.vue?macro=true";
import { default as editmXJLgynMFRmtjWCr8CVBSYMGBjvIrnUYQvulxTvMI6kMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/tests/[id]/edit/qn/[qnid]/edit.vue?macro=true";
import { default as newQrK1_45vW_45sC6uXshiyzsD_jVZU8k65owy_HsTko_45gkUsMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/tests/[id]/edit/qn/new.vue?macro=true";
import { default as selectCrVv7LMDNhN2Ng_45dk1YvQs5bsrgSMX4SUWBMc5McIC0Meta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/tests/[id]/edit/select.vue?macro=true";
import { default as indexc3um5_CqsWaKZnJW7CfUho3vK_fNlRt9xrj_451k_45v1QoMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/tests/[id]/index.vue?macro=true";
import { default as indexjIMNLYHmT63CG8PA1_45zs1xADCfywqEKn6rljZw2jeNgMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/tests/index.vue?macro=true";
import { default as newetJmGVcRXZqaP_g4UGdw8m2MbfshNyWP2GDX5B09U3YMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/tests/new.vue?macro=true";
import { default as editLMPkDqqJ3J9N3VWm0T51GGTvYiV2VsHPguNA8MiAWOgMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/users/[id]/edit.vue?macro=true";
import { default as indexNShUzqqsii_45QuBvfl81rAiA_45QI8ri9qXc0Cg0f5snbwMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/users/[id]/index.vue?macro=true";
import { default as indexayRHa7jUA270HVQ13FkXxNQJalR3bFIiF8iJVbV_GHYMeta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/users/index.vue?macro=true";
import { default as newNnQWZnhsdPEY8jGJWoAVHgItryGTIzTKGaFEJaqS_w4Meta } from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/users/new.vue?macro=true";
export default [
  {
    name: "account",
    path: "/account",
    meta: indexHYEe7k9ORx6bvJSfB8wp_45e3vvZa70GcK2zz2zL5rW2kMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/account/index.vue")
  },
  {
    name: "categories",
    path: "/categories",
    meta: indexgXSpUHY32KTjDX5fbdJYQr1sziLmk1hDOHESECEW_45SEMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/categories/index.vue")
  },
  {
    name: "chapters",
    path: "/chapters",
    meta: index7lxz1bgx8ONqiqb8LEO63B5gtUtJfcMfYdYhJqHGHjYMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/chapters/index.vue")
  },
  {
    name: "cleanup",
    path: "/cleanup",
    meta: indexX0D1EmwSwA9rrxrg1sWUcEj2F62AlCFhHhdlXjdsgAIMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/cleanup/index.vue")
  },
  {
    name: "index",
    path: "/",
    meta: index9EjCqR853zaLgX01ZcNmb06QlfZvLLGPMWcp8a_45NPOwMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: indexF3ecmobjSdIcQtApncWmVjLjvTsDikm6aN1d2emH0SsMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/login/index.vue")
  },
  {
    name: "notifications-id",
    path: "/notifications/:id()",
    meta: indexsQVCK6o94EMkK6_45W4ptxyf7q1bV5lNclyIjc3aCz49AMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/notifications/[id]/index.vue")
  },
  {
    name: "notifications",
    path: "/notifications",
    meta: index6BaUd3AiC051xfn4e7wY3YVhUU5s9_45LZcKz6lROIKmUMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/notifications/index.vue")
  },
  {
    name: "question-banks-id-deleted",
    path: "/question-banks/:id()/deleted",
    meta: deletedbubsCaZV3TcsMVjhSNFe9_45qwvlLk5ecceF_45TEbKarUoMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/question-banks/[id]/deleted.vue")
  },
  {
    name: "question-banks-id-edit",
    path: "/question-banks/:id()/edit",
    meta: indexQQbGblQfygCT73nBjEdO7zGGvcgNpTRlbXhFOuMDyI4Meta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/question-banks/[id]/edit/index.vue")
  },
  {
    name: "question-banks-id-edit-qn-qnid-duplicate",
    path: "/question-banks/:id()/edit/qn/:qnid()/duplicate",
    meta: duplicate6s_457cITf1rY5qWEm_81ymphY9r60pfb27baUahZJGuAMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/question-banks/[id]/edit/qn/[qnid]/duplicate.vue")
  },
  {
    name: "question-banks-id-edit-qn-qnid-edit",
    path: "/question-banks/:id()/edit/qn/:qnid()/edit",
    meta: editN31sBl7OKbWLd9Dw4CyAAISKba7mK2v3dtsnqRCsYxkMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/question-banks/[id]/edit/qn/[qnid]/edit.vue")
  },
  {
    name: "question-banks-id-edit-qn-new",
    path: "/question-banks/:id()/edit/qn/new",
    meta: newm_45KbzVdr8rNp99mVxQ2iHbOt4Z1XfIv1l4ZmLVKPhkEMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/question-banks/[id]/edit/qn/new.vue")
  },
  {
    name: "question-banks-id",
    path: "/question-banks/:id()",
    meta: index8Vi1sN1DqVIKgpPU9Rl_Vfj4T7Vwo0Xh_0t4kft_EwIMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/question-banks/[id]/index.vue")
  },
  {
    name: "question-banks-id-narratives",
    path: "/question-banks/:id()/narratives",
    meta: indexFGoH14NKrcD8XrafKdILXFQQTjI8Da4n0oP_AMf9kksMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/question-banks/[id]/narratives/index.vue")
  },
  {
    name: "question-banks",
    path: "/question-banks",
    meta: indexY33DT5Fvo5N60wGK7IC6WuTA_C9CxUIvSUfIcvkpRNsMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/question-banks/index.vue")
  },
  {
    name: "question-banks-new",
    path: "/question-banks/new",
    meta: new1D8kLGikmRi4HFaYjjpiWn8KAhq_vWt2rjpKMV3NwaYMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/question-banks/new.vue")
  },
  {
    name: "question-banks-search",
    path: "/question-banks/search",
    meta: indexzstVEwUZrGZWtqe5Lc5Lo_45zpt8IQHPLc6KPyXW7JjagMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/question-banks/search/index.vue")
  },
  {
    name: "reports-id-edit",
    path: "/reports/:id()/edit",
    meta: editIqBOFpQdfKAIwmBbs90rYUKRm_Y_45K0OgaNrLh9Y8lCYMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/reports/[id]/edit.vue")
  },
  {
    name: "reports-id",
    path: "/reports/:id()",
    meta: index_45gzonjzWKGp4XqT6kTm8b3M_45BMvq_452XiNgAhRAGOqdUMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/reports/[id]/index.vue")
  },
  {
    name: "reports",
    path: "/reports",
    meta: indexGT3GcU_45qj3vC8GShc2i_458GKY608_sf_45zaWl2v0QOpxoMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/reports/index.vue")
  },
  {
    name: "reports-new",
    path: "/reports/new",
    meta: newuxXPy2PdUg2h7dDlanddx4wSE0NzXv5uNLtN3AHtS8YMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/reports/new.vue")
  },
  {
    name: "reset",
    path: "/reset",
    meta: indexCnEee1xWSxU9WR_LT9DwEkx7FxiqXK7jgvKJAgPA7D4Meta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/reset/index.vue")
  },
  {
    name: "support",
    path: "/support",
    meta: indexxlGQvGVhK_OiTgFomDrtTK5ukvp3G_45kwIALvJHSu4_EMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/support/index.vue")
  },
  {
    name: "tags",
    path: "/tags",
    meta: indexBTlGHG0oLFrW81dtBdVgtu6CWl3_45huddQ2tLz6lMxpIMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/tags/index.vue")
  },
  {
    name: "tests-id-edit",
    path: "/tests/:id()/edit",
    meta: indexnl62lcqsaknW0YJq1uFTrmnC1AiMRA6Yf5be_iq4adcMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/tests/[id]/edit/index.vue")
  },
  {
    name: "tests-id-edit-qn-qnid-duplicate",
    path: "/tests/:id()/edit/qn/:qnid()/duplicate",
    meta: duplicate_45xnWMsIj074fOj8Zrc4NlDa8JraCOOaUIVu8t4hD1qcMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/tests/[id]/edit/qn/[qnid]/duplicate.vue")
  },
  {
    name: "tests-id-edit-qn-qnid-edit",
    path: "/tests/:id()/edit/qn/:qnid()/edit",
    meta: editmXJLgynMFRmtjWCr8CVBSYMGBjvIrnUYQvulxTvMI6kMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/tests/[id]/edit/qn/[qnid]/edit.vue")
  },
  {
    name: "tests-id-edit-qn-new",
    path: "/tests/:id()/edit/qn/new",
    meta: newQrK1_45vW_45sC6uXshiyzsD_jVZU8k65owy_HsTko_45gkUsMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/tests/[id]/edit/qn/new.vue")
  },
  {
    name: "tests-id-edit-select",
    path: "/tests/:id()/edit/select",
    meta: selectCrVv7LMDNhN2Ng_45dk1YvQs5bsrgSMX4SUWBMc5McIC0Meta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/tests/[id]/edit/select.vue")
  },
  {
    name: "tests-id",
    path: "/tests/:id()",
    meta: indexc3um5_CqsWaKZnJW7CfUho3vK_fNlRt9xrj_451k_45v1QoMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/tests/[id]/index.vue")
  },
  {
    name: "tests",
    path: "/tests",
    meta: indexjIMNLYHmT63CG8PA1_45zs1xADCfywqEKn6rljZw2jeNgMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/tests/index.vue")
  },
  {
    name: "tests-new",
    path: "/tests/new",
    meta: newetJmGVcRXZqaP_g4UGdw8m2MbfshNyWP2GDX5B09U3YMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/tests/new.vue")
  },
  {
    name: "users-id-edit",
    path: "/users/:id()/edit",
    meta: editLMPkDqqJ3J9N3VWm0T51GGTvYiV2VsHPguNA8MiAWOgMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/users/[id]/edit.vue")
  },
  {
    name: "users-id",
    path: "/users/:id()",
    meta: indexNShUzqqsii_45QuBvfl81rAiA_45QI8ri9qXc0Cg0f5snbwMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/users/[id]/index.vue")
  },
  {
    name: "users",
    path: "/users",
    meta: indexayRHa7jUA270HVQ13FkXxNQJalR3bFIiF8iJVbV_GHYMeta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/users/index.vue")
  },
  {
    name: "users-new",
    path: "/users/new",
    meta: newNnQWZnhsdPEY8jGJWoAVHgItryGTIzTKGaFEJaqS_w4Meta || {},
    component: () => import("C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/pages/users/new.vue")
  }
]