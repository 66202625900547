import payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_jjl2DFTrQxMG7TqNyE_rvcIV8r2uFVLO_Sius2B7lXg from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import html2canvas_client_E8ZSmNfOl_UmdmZUq9t2qNC6I5Iaojxva6CoM_8mqd4 from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/plugins/html2canvas.client.ts";
import html2pdf_client_J_ZPtDusnm_qGEp3IRhfluO8L5y6fmb_K6__WCJj_GM from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/plugins/html2pdf.client.ts";
import vuetify_hjFy4UiBVKu2U8_BW9ggkFzfvErKr3wFgTHpa6TF5Ds from "C:/wamp64/www/IEC/Test_Bank_System/IEC_Test_Bank_System_APP/plugins/vuetify.ts";
export default [
  payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M,
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  plugin_jjl2DFTrQxMG7TqNyE_rvcIV8r2uFVLO_Sius2B7lXg,
  html2canvas_client_E8ZSmNfOl_UmdmZUq9t2qNC6I5Iaojxva6CoM_8mqd4,
  html2pdf_client_J_ZPtDusnm_qGEp3IRhfluO8L5y6fmb_K6__WCJj_GM,
  vuetify_hjFy4UiBVKu2U8_BW9ggkFzfvErKr3wFgTHpa6TF5Ds
]