import type { ErrorT, User } from '~/types'
import { useAppStore } from '~/stores/app'
import { useCategoryStore } from '~/stores/category'
import { useInstructorStore } from '~/stores/instructor'
import { usePageStore } from '~/stores/page'
import { useQuestionBankStore } from '~/stores/question-bank'
import { useQuestionStore } from '~/stores/question'
import { useTagStore } from '~/stores/tag'
import { useTestStore } from '~/stores/test'
import { useUserStore } from '~/stores/user'
import { useSnackbarStore } from '~/stores/snackbar'
import { useAPI } from '~~/composables/useAPI'

export const useAuthStore = defineStore(
  'authStore',
  () => {
    const config = useRuntimeConfig()
    const userData = ref<User>()
    const isAuthenticated = ref(false)
    const isLoggedIn = ref(false)
    const isRedirect = ref(false)
    const reloadPage = ref(false)
    const isValid = ref(true)
    const isPending = ref(false)
    const isPasswordSet = ref(false)
    const { setSnackbar } = useSnackbarStore()

    const lastRoute = ref('')
    const { showError } = useAPI()

    const headers = computed(() => {
      return {
        Accept: 'application/json',
        'X-CSRF-TOKEN': token.value,
      }
    })

    const token = ref('')
    const {
      data: tokenData,
      error: tokenError,
      execute: execToken,
    } = useFetch(`${config.public.API_URL}/csrf`, {
      method: 'GET',
      baseURL: config.public.API_URL,
      headers: headers,
      immediate: false,
      watch: false,
    })

    const loginForm = ref({
      email: '',
      password: '',
    })
    const loginPost = computed(() => {
      return loginForm.value
    })
    const {
      data: loginData,
      error: loginError,
      execute: execLogin,
    } = useFetch(`${config.public.API_URL}/login`, {
      method: 'POST',
      baseURL: config.public.API_URL,
      headers: headers,
      body: loginPost,
      immediate: false,
      watch: false,
    })

    const emailForm = ref({
      email: '',
    })
    const emailPost = computed(() => {
      return emailForm.value
    })
    const {
      data: resetData,
      error: resetError,
      execute: execSendResetEmail,
    } = useFetch(`${config.public.API_URL}/forgotpassword`, {
      method: 'POST',
      baseURL: config.public.API_URL,
      headers: headers,
      body: emailPost,
      immediate: false,
      watch: false,
    })

    const passwordForm = ref({
      email: '',
      code: '',
      repassword: '',
      password: '',
    })
    const passwordPost = computed(() => {
      return passwordForm.value
    })
    const {
      data: newPasswordData,
      error: newPasswordError,
      execute: execNewPassword,
    } = useFetch(`${config.public.API_URL}/new_password`, {
      method: 'POST',
      baseURL: config.public.API_URL,
      headers: headers,
      body: passwordPost,
      watch: false,
      immediate: false,
    })

    async function fetchToken(): Promise<void> {
      console.log(`fetchToken()`)

      await execToken()

      if (tokenError.value) {
        showError(tokenError.value as ErrorT)
      } else if (tokenData.value) {
        console.log(`  tokenData = ${JSON.stringify(tokenData.value)}`)
        token.value = tokenData.value as string
      }
      isPending.value = false
    }

    function setUser(newUser: User) {
      userData.value = newUser
    }

    async function loginUser(): Promise<boolean> {
      console.log(`loginUser(): loginForm = ${JSON.stringify(loginForm.value)}`)
      await resetStores()

      await fetchToken()
      console.log(`  headers = ${JSON.stringify(headers.value)}`)
      isPending.value = true

      await execLogin()

      if (loginError.value) {
        showError(loginError.value as ErrorT)
        isLoggedIn.value = false
      } else {
        console.log(`LOGGED IN!`)
        fetchToken()
        setAuth(true)
        isLoggedIn.value = true
      }
      isPending.value = false
      return isLoggedIn.value
    }

    async function sendResetEmail(email: string, director = false): Promise<void> {
      console.log(`sendResetEmail(${email}, ${director})`)
      if (director) await fetchToken()

      emailForm.value.email = email
      isPending.value = true

      await execSendResetEmail()

      if (resetError.value) {
        console.log(`sendResetEmail ERROR! - ${JSON.stringify(resetError.value)},
            data = ${resetData.value}`)
        if (resetError.value.statusCode === 403) {
          isRedirect.value = true
          await logout()
        } else if (resetError.value.data) {
          setSnackbar({
            type: `error`,
            text: resetError.value.data.message.email ? resetError.value.data.message.email[0] : resetError.value.data.message,
          })
        } else {
          setSnackbar({
            type: `error`,
            text: `Server communication error. Please try again.`,
          })
        }
      } else if (resetData.value) {
        setSnackbar({
          type: `success`,
          text: 'Password Reset email sent.',
        })
      }
      isPending.value = false
    }

    async function setNewPassword(password: string, repassword: string, email: string, code: string): Promise<boolean> {
      console.log(`setNewPassword()`)
      await fetchToken()
      isPending.value = true

      passwordForm.value = {
        email: email,
        code: code,
        repassword: repassword,
        password: password,
      }

      await execNewPassword()

      if (newPasswordError.value) {
        console.log(`setNewPassword ERROR! - ${JSON.stringify(newPasswordError.value)},
            data = ${newPasswordData.value}`)
        if (newPasswordError.value.statusCode === 403) {
          isRedirect.value = true
          await logout()
        } else if (newPasswordError.value.data) {
          setSnackbar({
            type: `error`,
            text: newPasswordError.value.data.message,
          })
        } else {
          setSnackbar({
            type: `error`,
            text: `Server communication error. Please try again.`,
          })
        }
        isPasswordSet.value = false
      } else if (newPasswordData.value) {
        setSnackbar({
          type: `success`,
          text: 'New password set.',
        })
        console.log(`New password set!`)
        isPasswordSet.value = true
      }
      isPending.value = false
      return isPasswordSet.value
    }

    async function logout() {
      console.log(`logout()`)

      isPending.value = true

      try {
        await $fetch(`${config.public.API_URL}/logout`, {
          method: 'GET',
          baseURL: config.public.API_URL,
        })

        await resetStores()
        await $reset()

        navigateTo({ path: `/login` })
        isPending.value = false
      } catch (e) {
        console.error(e)
        console.log(`logout ERROR! - ${JSON.stringify(e)}`)
      }
    }

    function setAuth(authenticated: boolean) {
      isAuthenticated.value = authenticated
    }

    async function resetStores() {
      useUserStore().$reset
      useAppStore().$reset
      useCategoryStore().$reset
      useInstructorStore().$reset
      usePageStore().$reset
      useQuestionBankStore().$reset
      useQuestionStore().$reset
      useTagStore().$reset
      useTestStore().$reset
    }
    function $reset() {
      userData.value = {} as User
      isAuthenticated.value = false
      isValid.value = true
      isPending.value = false
      loginForm.value = {
        email: '',
        password: '',
      }
      token.value = ''
    }

    return { userData, isAuthenticated, isRedirect, reloadPage, isValid, isPending, loginForm, token, lastRoute, headers, setUser, loginUser, logout, fetchToken, setAuth, resetStores, $reset, sendResetEmail, setNewPassword }
  },
  {
    persist: {
      storage: piniaPluginPersistedstate.localStorage(),
    },
  }
)

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
}
