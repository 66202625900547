import { storeToRefs } from 'pinia'
import type { ErrorT, Category, User } from '~/types'
import { useSnackbarStore } from '~/stores/snackbar'
import { useAuthStore } from '~/stores/auth'
import { useFilterStore } from '~/stores/filter'
import { useAPI } from '~~/composables/useAPI'

export const useCategoryStore = defineStore('categoryStore', () => {
  const { setSnackbar } = useSnackbarStore()
  const { headers } = storeToRefs(useAuthStore())
  const config = useRuntimeConfig()

  const { showError } = useAPI()

  const { chapterFilters } = storeToRefs(useFilterStore())
  const categories = ref<Category[]>([])
  const category = ref<Category>()
  const tempCategory = ref<Category>()
  const categorySubscriptions = ref<Category[]>([])
  const categorySubscriptionIDs = ref<number[]>([])
  const instructors = ref<User[]>([])
  const isPending = ref(false)
  const error = ref(null)
  const total = ref(0)
  const cacheBuster = ref(0)
  const selectedCategories = ref<{ [key: string]: number }>({})
  const selectedCategory = ref<Category>()
  const parameters = ref({
    page: 1,
    per_page: 10,
    order_direction: '',
    groupBy: [],
    order_by: 'title',
    search: '',
    watch: [cacheBuster.value],
  })
  const subscriptionParameters = ref({
    page: 1,
    per_page: 10,
    order_direction: '',
    groupBy: [],
    order_by: 'title',
    search: '',
    watch: [cacheBuster.value],
  })
  const params = computed(() => {
    return { ...parameters.value, ...selectedCategories.value }
  })
  const subscriptionParams = computed(() => {
    return { ...subscriptionParameters.value, per_page: 10000 }
  })

  const getCategoryById = computed(() => {
    // console.log(`getCategoryById()`)
    return (category_id: number) => categories.value.find((category) => category.id === category_id)
  })
  const isValid = computed(() => {
    return tempCategory.value && tempCategory.value.title !== '' ? true : false
  })

  const {
    data: categoriesData,
    error: categoriesError,
    execute: execCategories,
  } = useFetch<{ total: number; data: Category[] }>(`${config.public.API_URL}/api/category/show_all`, {
    method: 'GET',
    baseURL: config.public.API_URL,
    headers: headers,
    params: params,
    immediate: false,
    watch: false,
  })

  async function fetchCategories(numPerPage?: number) {
    console.log(`fetchCategories(${numPerPage})`)
    isPending.value = true

    if (numPerPage) {
      parameters.value.per_page = numPerPage
    }

    selectedCategories.value = {}

    if (chapterFilters.value.length) {
      chapterFilters.value.forEach((chapter: number, index: number) => {
        selectedCategories.value[`selected_chapters[${index}]`] = chapter
      })
    }
    // console.log(`params = ${JSON.stringify(params.value)}`)

    await execCategories()

    if (categoriesError.value) {
      showError(categoriesError.value as ErrorT)
    } else if (categoriesData.value) {
      // console.log(`  categories = ${JSON.stringify(categoriesData.value)}`)
      total.value = categoriesData.value.total
      categories.value = categoriesData.value.data
      await fetchSubscriptions()
    }
    isPending.value = false
  }

  const {
    data: subscriptionsData,
    error: subscriptionsError,
    execute: execSubscriptions,
  } = useFetch<{ data: Category[] }>(`${config.public.API_URL}/api/categories`, {
    method: 'GET',
    baseURL: config.public.API_URL,
    headers: headers,
    params: subscriptionParams,
    immediate: false,
    watch: false,
  })

  async function fetchSubscriptions() {
    console.log(`fetchSubscriptions()`)
    isPending.value = true

    await execSubscriptions()

    if (subscriptionsError.value) {
      showError(subscriptionsError.value as ErrorT)
    } else if (subscriptionsData.value) {
      // console.log(`  subscriptionsData = ${JSON.stringify(subscriptionsData.value)}`)
      categorySubscriptions.value = subscriptionsData.value.data
      categorySubscriptionIDs.value = subscriptionsData.value.data.map((category: any) => category.id)
      // console.log(`  categorySubscriptions = ${JSON.stringify(categorySubscriptions.value)},
      // categorySubscriptionIDs = ${categorySubscriptionIDs.value}`)
    }
    isPending.value = false
  }

  async function fetchCategory(categoryId: number) {
    // console.log(`fetchCategory(${categoryId})`)
    isPending.value = true

    const tempCategory = categories.value.find((category) => category.id === categoryId)
    if (tempCategory) {
      category.value = { ...tempCategory }
      isPending.value = false
    }
  }

  async function setCategoryById(categoryId: number) {
    const tempCategory = await categories.value.find((category) => category.id === categoryId)
    if (tempCategory) {
      category.value = tempCategory
    }
    // console.log(`setCategoryById(${categoryId}): category = ${JSON.stringify(category.value)}`)
  }

  const {
    data: createData,
    error: createError,
    execute: execCreate,
  } = useFetch<{ message: string }>(`${config.public.API_URL}/api/categories`, {
    method: 'POST',
    baseURL: config.public.API_URL,
    headers: headers,
    body: category,
    immediate: false,
    watch: false,
  })

  async function createCategory() {
    isPending.value = true
    console.log(`createCategory(): headers = ${JSON.stringify(headers.value)}`)

    await execCreate()

    if (createError.value) {
      if (createError.value.data.message === 'Duplicate Entry') {
        setSnackbar({
          type: `error`,
          text: `Category Already Exists`,
        })
      } else {
        showError(createError.value as ErrorT)
      }
      isPending.value = false

      return false
    } else if (createData.value) {
      cacheBuster.value += 1
      await fetchCategories()

      setSnackbar({
        type: `success`,
        text: `New Category successfully created!`,
      })
      isPending.value = false

      return createData.value.message
    }
  }

  const categoryURL = computed(() => `${config.public.API_URL}/api/categories/${category.value ? category.value.id : 0}`)

  const {
    data: updateData,
    error: updateError,
    execute: execUpdate,
  } = useFetch(
    () => {
      return categoryURL.value
    },
    {
      method: 'PUT',
      baseURL: config.public.API_URL,
      headers: headers,
      body: category,
      immediate: false,
      watch: false,
    }
  )

  async function updateCategory() {
    isPending.value = true
    console.log(`\n updateCategory(): categoryURL = ${categoryURL.value}`)
    // console.log(` category = ${JSON.stringify(category.value)}`)

    await execUpdate()

    if (updateError.value) {
      if (updateError.value.data.message === 'Duplicate Entry') {
        setSnackbar({
          type: `error`,
          text: `Category Already Exists`,
        })
      } else {
        showError(createError.value as ErrorT)
      }
      isPending.value = false

      return false
    } else if (updateData.value) {
      // console.log(`  ${JSON.stringify(data)}`)
      cacheBuster.value += 1
      await fetchCategories()

      setSnackbar({
        type: `success`,
        text: `Category updated successfully`,
      })
      isPending.value = false

      return (updateData.value as { message: string }).message
    }
  }

  const {
    data: deleteData,
    error: deleteError,
    execute: execDelete,
  } = useFetch(
    () => {
      return categoryURL.value
    },
    {
      method: 'delete',
      baseURL: config.public.API_URL,
      headers: headers,
      immediate: false,
      watch: false,
    }
  )

  async function deleteCategory(id: number) {
    // console.log(`deleteCategory(${id})`)

    await execDelete()

    if (deleteError.value) {
      showError(deleteError.value as ErrorT)
    } else if (deleteData.value) {
      setSnackbar({
        type: `success`,
        text: 'Category successfully deleted.',
      })
    }
    isPending.value = false
  }

  const subscribeID = ref(0)
  const subscribeURL = computed(() => `${config.public.API_URL}/api/category/sub/${subscribeID.value ? subscribeID.value : 0}`)

  const {
    data: subscribeData,
    error: subscribeError,
    execute: execSubscribe,
  } = useFetch(
    () => {
      return subscribeURL.value
    },
    {
      method: 'POST',
      baseURL: config.public.API_URL,
      headers: headers,
      immediate: false,
      watch: false,
    }
  )

  async function subscribe(id: number) {
    // console.log(`subscribe(${id})`)
    isPending.value = true
    subscribeID.value = id

    await execSubscribe()

    if (subscribeError.value) {
      showError(subscribeError.value as ErrorT)
    } else if (subscribeData.value) {
      // console.log(`  ${JSON.stringify(data)}`)
      cacheBuster.value += 1
      await fetchCategories()

      setSnackbar({
        type: `success`,
        text: `Subscribed to Category successfully!`,
      })
    }
    isPending.value = false
  }

  const unsubscribeID = ref(0)
  const unsubscribeURL = computed(() => `${config.public.API_URL}/api/category/unsub/${unsubscribeID.value ? unsubscribeID.value : 0}`)

  const {
    data: unsubscribeData,
    error: unsubscribeError,
    execute: execUnsubscribe,
  } = useFetch(
    () => {
      return unsubscribeURL.value
    },
    {
      method: 'POST',
      baseURL: config.public.API_URL,
      headers: headers,
      immediate: false,
      watch: false,
    }
  )

  async function unsubscribe(id: number) {
    // console.log(`unsubscribe(${id})`)
    isPending.value = true
    unsubscribeID.value = id

    await execUnsubscribe()

    if (unsubscribeError.value) {
      showError(unsubscribeError.value as ErrorT)
    } else {
      cacheBuster.value += 1
      await fetchCategories()

      setSnackbar({
        type: `success`,
        text: `Unsubscribed to Category successfully!`,
      })
    }
    isPending.value = false
  }

  const directorSubID = ref(0)
  const directorSubURL = computed(() => `${config.public.API_URL}/api/category/director_sub/${directorSubID.value ? directorSubID.value : 0}`)
  const directorSubBody = computed(() => {
    user_array: instructors.value
  })

  const {
    data: directorSubData,
    error: directorSubError,
    execute: execDirectorSub,
  } = useFetch(
    () => {
      return directorSubURL.value
    },
    {
      method: 'POST',
      baseURL: config.public.API_URL,
      headers: headers,
      body: directorSubBody,
      immediate: false,
      watch: false,
    }
  )

  async function directorSub(id: number) {
    // console.log(`directorSub()`)
    isPending.value = true
    directorSubID.value = id

    await execDirectorSub()

    if (directorSubError.value) {
      showError(directorSubError.value as ErrorT)
    } else if (directorSubData.value) {
      // console.log(`  ${JSON.stringify(directorSubData.value)}`)
    }
    isPending.value = false
  }

  const directorUnsubID = ref(0)
  const directorUnsubURL = computed(() => `${config.public.API_URL}/api/category/director_unsub/${directorUnsubID.value ? directorUnsubID.value : 0}`)
  const directorUnsubBody = computed(() => {
    user_array: instructors.value
  })

  const {
    data: directorUnsubData,
    error: directorUnsubError,
    execute: execDirectorUnsub,
  } = useFetch(
    () => {
      return directorUnsubURL.value
    },
    {
      method: 'POST',
      baseURL: config.public.API_URL,
      headers: headers,
      body: directorUnsubBody,
      immediate: false,
      watch: false,
    }
  )

  async function directorUnsub(id: number) {
    // console.log(`directorUnsub()`)
    isPending.value = true
    directorUnsubID.value = id

    await execDirectorUnsub()

    if (directorUnsubError.value) {
      showError(directorUnsubError.value as ErrorT)
    } else if (directorUnsubData.value) {
      // console.log(`  ${JSON.stringify(directorUnsubData.value)}`)
    }
    isPending.value = false
  }

  function $reset() {
    // console.log(`$reset()`)
    isPending.value = false
    categories.value = []
    category.value = {} as Category
    tempCategory.value = {} as Category
    categorySubscriptions.value = []
    categorySubscriptionIDs.value = []
    instructors.value = []
    isPending.value = false
    error.value = null
    total.value = 0
    cacheBuster.value = 0
  }

  return {
    categories,
    category,
    tempCategory,
    categorySubscriptions,
    categorySubscriptionIDs,
    selectedCategory,
    instructors,
    isPending,
    error,
    total,
    cacheBuster,
    parameters,
    getCategoryById,
    params,
    isValid,
    fetchCategories,
    fetchSubscriptions,
    fetchCategory,
    setCategoryById,
    createCategory,
    updateCategory,
    deleteCategory,
    subscribe,
    unsubscribe,
    directorSub,
    directorUnsub,
    $reset,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCategoryStore, import.meta.hot))
}
