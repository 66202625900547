import { storeToRefs } from 'pinia'
import type { ErrorT, Notification } from '~/types'
import { useSnackbarStore } from '~/stores/snackbar'
import { useAuthStore } from '~/stores/auth'
import { useFilterStore } from '~/stores/filter'
import { useAPI } from '~~/composables/useAPI'

export const useNotificationStore = defineStore('notificationStore', () => {
  const { setSnackbar } = useSnackbarStore()
  const { headers } = storeToRefs(useAuthStore())
  const { viewFilters } = storeToRefs(useFilterStore())
  const config = useRuntimeConfig()
  const { showError } = useAPI()

  const allNotifications = ref<Notification[]>([])
  const notifications = ref<Notification[]>([])
  const notification = ref<Notification>()
  const notificationUpdate = ref<Notification>()
  const isLoading = ref(false)
  const isPending = ref(false)
  const isValid = ref(true)
  const error = ref(null)
  const total = ref(0)
  const cacheBuster = ref(0)
  const selectedNotificationIDs = ref<number[]>([])
  const selectedViewFilters = ref<{ [key: string]: number }>({})

  const parameters = ref({
    page: 1,
    per_page: 10,
    order_direction: '',
    groupBy: [],
    order_by: 'title',
    search: '',
    watch: [cacheBuster.value],
  })
  const params = computed(() => {
    return { ...parameters.value }
  })
  const paramsAll = computed(() => {
    return { ...parameters.value, per_page: 10000 }
  })
  const paramsViews = computed(() => {
    return { ...parameters.value, ...selectedViewFilters.value }
  })

  const allNotificationIDs = computed(() => {
    return notifications.value.map((item) => item.id)
  })

  const unreadNotifications = computed(() => {
    return allNotifications.value.filter((item) => item.read === 0).length
  })

  interface NotificationsAPIData {
    data: Notification[]
    total: number
  }

  const {
    data: allNotificationsData,
    error: allNotificationsError,
    execute: execAllNotifications,
  } = useFetch<NotificationsAPIData>(`${config.public.API_URL}/api/notifications`, {
    method: 'GET',
    baseURL: config.public.API_URL,
    headers: headers,
    params: paramsAll,
    immediate: false,
    watch: false,
  })

  async function fetchAllNotifications() {
    // console.log(`fetchAllNotifications()`)
    isPending.value = true

    await execAllNotifications()

    if (allNotificationsError.value) {
      showError(allNotificationsError.value as ErrorT)
    } else if (allNotificationsData.value) {
      allNotifications.value = allNotificationsData.value.data
      total.value = allNotificationsData.value.total

      // console.log(`  notifications = ${JSON.stringify(allNotificationsData.value)}`)
      // console.log(`  unreadNotifications = ${unreadNotifications.value}`)
    }
    isPending.value = false
  }

  const {
    data: notificationsData,
    error: notificationsError,
    execute: execNotifications,
  } = useFetch<NotificationsAPIData>(`${config.public.API_URL}/api/notifications`, {
    method: 'GET',
    baseURL: config.public.API_URL,
    headers: headers,
    params: paramsViews,
    immediate: false,
    watch: false,
  })

  async function fetchNotifications() {
    if (isPending.value) return
    // console.log(`fetchNotifications()`)
    isPending.value = true

    selectedViewFilters.value = {}

    if (viewFilters.value.length) {
      viewFilters.value.forEach((view: number, index: number) => {
        selectedViewFilters.value[`selected_views[${index}]`] = view
      })
    }

    await execNotifications()

    if (notificationsError.value) {
      showError(notificationsError.value as ErrorT)
    } else if (notificationsData.value) {
      // console.log(`  notifications = ${JSON.stringify(notificationsData.value)}`)
      notifications.value = notificationsData.value.data
      total.value = notificationsData.value.total
    }
    isPending.value = false
  }

  const notificationID = ref(0)
  const notificationURL = computed(() => `${config.public.API_URL}/api/notifications/${notificationID.value ? notificationID.value : 0}`)

  const {
    data: notificationData,
    error: notificationError,
    execute: execNotification,
  } = useFetch<Notification[]>(
    () => {
      return notificationURL.value
    },
    {
      method: 'GET',
      baseURL: config.public.API_URL,
      headers: headers,
      immediate: false,
      watch: false,
    }
  )

  async function fetchNotification(notificationId: number) {
    console.log(`fetchNotification(${notificationId})`)
    isPending.value = true
    notificationID.value = notificationId

    await execNotification()

    if (notificationError.value) {
      showError(notificationError.value as ErrorT)
    } else if (notificationData.value) {
      // console.log(`  notificationData.value = ${JSON.stringify(notificationData.value)}`)
      if (notificationData.value.length === 1) {
         notification.value = notificationData.value[0] as Notification
      } else {
         notification.value = notificationData.value[0] as Notification
         notificationUpdate.value = notificationData.value[1] as Notification
      }
      cacheBuster.value += 1
      await fetchAllNotifications()
    }
    isPending.value = false
  }

  const user_question = ref(0)
  const admin_question = ref(0)
  const message_id = ref(0)

  const approveURL = computed(() => `${config.public.API_URL}/api/question_approve_replace`)
  const approveBody = computed(() => {
    return { user_question: user_question.value, admin_question: admin_question.value, message_id: message_id.value }
  })

  const {
    data: approveData,
    error: approveError,
    execute: execApprove,
  } = useFetch(
    () => {
      return approveURL.value
    },
    {
      method: 'POST',
      baseURL: config.public.API_URL,
      headers: headers,
      body: approveBody,
      immediate: false,
      watch: false,
    }
  )

  async function approveReplace(__user_question: number, __admin_question: number, __message_id: number) {
    isPending.value = true

    user_question.value = __user_question
    admin_question.value = __admin_question
    message_id.value = __message_id

    await execApprove()

    if (approveError.value) {
      showError(approveError.value as ErrorT)
    } else if (approveData.value) {
      setSnackbar({
        type: `success`,
        text: `Question Approved and Replaced`,
      })
      // console.log(`Question Bank Created! - ${JSON.stringify(approveData.value)}`)
      navigateTo({ path: '/notifications/' })
    }
    isPending.value = false
  }

  const questionID = ref(0)
  const delinkURL = computed(() => `${config.public.API_URL}/api/question_delink/${questionID.value ? questionID.value : 0}`)

  const {
    data: delinkData,
    error: delinkError,
    execute: execDelink,
  } = useFetch(
    () => {
      return delinkURL.value
    },
    {
      method: 'POST',
      baseURL: config.public.API_URL,
      headers: headers,
      immediate: false,
      watch: false,
    }
  )

  async function delinkNote(user_question: number) {
    isPending.value = true
    questionID.value = user_question

    await execDelink()

    if (delinkError.value) {
      showError(delinkError.value as ErrorT)
    } else if (delinkData.value) {
      setSnackbar({
        type: `success`,
        text: `Message has been deleted`,
      })
      // console.log(`Question Bank Created! - ${JSON.stringify(delinkData.value)}`)
      navigateTo({ path: '/notifications/' })
    }
    isPending.value = false
  }

  const {
    data: deleteData,
    error: deleteError,
    execute: execDelete,
  } = useFetch(
    () => {
      return notificationURL.value
    },
    {
      method: 'delete',
      baseURL: config.public.API_URL,
      headers: headers,
      immediate: false,
      watch: false,
    }
  )

  async function deleteNote(notification: number, reload = false) {
    isPending.value = true
    notificationID.value = notification

    await execDelete()

    if (deleteError.value) {
      showError(deleteError.value as ErrorT)
    } else if (deleteData.value) {
      setSnackbar({
        type: `success`,
        text: `Notification Deleted`,
      })
      // console.log(`Notification Deleted! - ${JSON.stringify(deleteData.value)}`)
      await fetchAllNotifications()
      if (!reload) navigateTo({ path: '/notifications/' })
    }
    isPending.value = false
  }

  const deletesObj = ref({})
  const deletesBody = computed(() => deletesObj.value)

  const deletesURL = computed(() => `${config.public.API_URL}/api/notifications/bulk_destroy`)
  const {
    data: deletesData,
    error: deletesError,
    execute: execDeletes,
  } = useFetch(
    () => {
      return deletesURL.value
    },
    {
      method: 'POST',
      baseURL: config.public.API_URL,
      headers: headers,
      body: deletesBody,
      immediate: false,
      watch: false,
    }
  )

  async function deleteNotes() {
    console.log(`deleteNotes(${selectedNotificationIDs.value})`)
    isPending.value = true
    deletesObj.value = {
      notification_list: selectedNotificationIDs.value,
    }

    await execDeletes()

    if (deletesError.value) {
      showError(deletesError.value as ErrorT)
    } else if (deletesData.value) {
      setSnackbar({
        type: `success`,
        text: 'Notification(s) successfully ignored.',
      })
      selectedNotificationIDs.value = []
      await fetchAllNotifications()
    }
    isPending.value = false
  }

  const readURL = computed(() => `${config.public.API_URL}/api/notifications/read_notification/${notificationID.value ? notificationID.value : 0}`)

  const {
    data: readData,
    error: readError,
    execute: execRead,
  } = useFetch(
    () => {
      return readURL.value
    },
    {
      method: 'post',
      baseURL: config.public.API_URL,
      headers: headers,
      immediate: false,
      watch: false,
    }
  )

  async function readNote(notification: number, reload = false) {
    isPending.value = true
    notificationID.value = notification

    await execRead()

    if (readError.value) {
      showError(readError.value as ErrorT)
      isPending.value = false
    } else if (readData.value) {
      setSnackbar({
        type: `success`,
        text: `Notification marked as Viewed`,
      })
      isPending.value = false
      await fetchAllNotifications()

      // console.log(`Notification Viewed! - ${JSON.stringify(readData.value)}`)
      if (!reload) navigateTo({ path: '/notifications/' })
    }
  }

  const readsObj = ref({})
  const readsBody = computed(() => readsObj.value)

  const readsURL = computed(() => `${config.public.API_URL}/api/notifications/bulk_read`)
  const {
    data: readsData,
    error: readsError,
    execute: execReads,
  } = useFetch(
    () => {
      return readsURL.value
    },
    {
      method: 'POST',
      baseURL: config.public.API_URL,
      headers: headers,
      body: readsBody,
      immediate: false,
      watch: false,
    }
  )

  async function readNotes() {
    console.log(`deleteNotes(${selectedNotificationIDs.value})`)
    isPending.value = true
    readsObj.value = {
      notification_list: selectedNotificationIDs.value,
    }

    await execReads()

    if (readsError.value) {
      showError(readsError.value as ErrorT)
      isPending.value = false
    } else if (readsData.value) {
      setSnackbar({
        type: `success`,
        text: 'Notification(s) successfully marked as Viewed.',
      })
      selectedNotificationIDs.value = []
      isPending.value = false
      await fetchAllNotifications()
    }
  }

  const unreadURL = computed(() => `${config.public.API_URL}/api/notifications/unread_notification/${notificationID.value ? notificationID.value : 0}`)
  const {
    data: unreadData,
    error: unreadError,
    execute: execUnread,
  } = useFetch(
    () => {
      return unreadURL.value
    },
    {
      method: 'post',
      baseURL: config.public.API_URL,
      headers: headers,
      immediate: false,
      watch: false,
    }
  )

  async function unreadNote(notification: number, reload = false) {
    isPending.value = true
    notificationID.value = notification

    await execUnread()

    if (unreadError.value) {
      showError(unreadError.value as ErrorT)
      isPending.value = false
    } else if (unreadData.value) {
      setSnackbar({
        type: `success`,
        text: `Notification marked as not viewed`,
      })
      isPending.value = false
      await fetchAllNotifications()
      // console.log(`Notification marked as not viewed! - ${JSON.stringify(unreadData.value)}`)
      if (!reload) navigateTo({ path: '/notifications/' })
    }
  }

  const unreadsObj = ref({})
  const unreadsBody = computed(() => unreadsObj.value)

  const unreadsURL = computed(() => `${config.public.API_URL}/api/notifications/bulk_unread`)
  const {
    data: unreadsData,
    error: unreadsError,
    execute: execUnreads,
  } = useFetch(
    () => {
      return unreadsURL.value
    },
    {
      method: 'POST',
      baseURL: config.public.API_URL,
      headers: headers,
      body: unreadsBody,
      immediate: false,
      watch: false,
    }
  )

  async function unreadNotes() {
    console.log(`unreadNotes(${selectedNotificationIDs.value})`)
    isPending.value = true
    unreadsObj.value = {
      notification_list: selectedNotificationIDs.value,
    }

    await execUnreads()

    if (unreadsError.value) {
      showError(unreadsError.value as ErrorT)
      isPending.value = false
    } else if (unreadsData.value) {
      setSnackbar({
        type: `success`,
        text: 'Notification(s) successfully marked as not Viewed.',
      })
      selectedNotificationIDs.value = []
      isPending.value = false
      await fetchAllNotifications()
    }
  }

  function $reset() {
    notifications.value = <Array<Notification>>[]
    notification.value = <Notification>{}
    notificationUpdate.value = <Notification>{}
    isLoading.value = false
    isPending.value = false
    isValid.value = true
    error.value = null
    total.value = 0
    cacheBuster.value = 0
  }

  return {
    allNotifications,
    notifications,
    notification,
    notificationUpdate,
    allNotificationIDs,
    unreadNotifications,
    selectedNotificationIDs,
    isLoading,
    isPending,
    isValid,
    error,
    total,
    cacheBuster,
    parameters,
    params,
    fetchAllNotifications,
    fetchNotifications,
    fetchNotification,
    approveReplace,
    delinkNote,
    deleteNote,
    deleteNotes,
    readNote,
    readNotes,
    unreadNote,
    unreadNotes,
    $reset,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNotificationStore, import.meta.hot))
}
