import { storeToRefs } from 'pinia'
import type { ErrorT, User } from '~/types'
import { useSnackbarStore } from '~/stores/snackbar'
import { useAuthStore } from '~/stores/auth'
import { useAPI } from '~~/composables/useAPI'

export const useInstructorStore = defineStore('instructorStore', () => {
   const { setSnackbar } = useSnackbarStore()
   const { headers } = storeToRefs(useAuthStore())
   const config = useRuntimeConfig()
   const { showError } = useAPI()

   const instructors = ref<User[]>([])
   const tempInstructors = ref<User[]>([])
   const instructor = ref<User>()
   const tempInstructor = ref<User>()
   const subscriptions = ref<number[]>()
   const tempSubscriptions = ref<number[]>()
   const isValid = ref(true)
   const isPending = ref(false)
   const error = ref(null)
   const total = ref(0)
   const cacheBuster = ref(0)
   const parameters = ref({
      page: 1,
      per_page: 10,
      order_direction: '',
      groupBy: [],
      order_by: 'lastName',
      search: '',
      watch: [cacheBuster.value]
   })
   const params = computed(() => {
      return { ...parameters.value }
   })

   const fullName = computed(() => instructor.value ? `${instructor.value.firstName} ${instructor.value.lastName}` : '')

   const categoryID = ref(0)
   const instructorsURL = computed(() => `${config.public.API_URL}/api/category/director_show/${categoryID.value ? categoryID.value : 0}`)

   const { data: allInstructorsData, error: allInstructorsError, execute: execAllInstructors } = useFetch(() => { return instructorsURL.value }, {
      method: 'GET',
      baseURL: config.public.API_URL,
      headers: headers,
      immediate: false,
      watch: false,
   })

   async function fetchAllInstructors(categoryId: number) {
      console.log(`fetchAllInstructors(${categoryId})`)
      isPending.value = true
      categoryID.value = categoryId

      await execAllInstructors()

      if (allInstructorsError.value) {
         showError(allInstructorsError.value as ErrorT)
      } else if (allInstructorsData.value) {
         // console.log(`  data = ${JSON.stringify(instructorsData.value)}`)
         instructors.value = (allInstructorsData.value as { data: User[] }).data

         subscriptions.value = instructors.value.filter((instructor) => instructor.subscribed === 1).map((instructor) => {
            return instructor.id as number
         })
         console.log(`  subscriptions = ${JSON.stringify(subscriptions.value)}`)
      }
      isPending.value = false
   }

   const { data: instructorsData, error: instructorsError, execute: execInstructors } = useFetch(() => { return instructorsURL.value }, {
      method: 'GET',
      baseURL: config.public.API_URL,
      headers: headers,
      params: params,
      immediate: false,
      watch: false,
   })

   async function fetchInstructors(categoryId: number) {
      console.log(`fetchInstructors()`)
      isPending.value = true
      categoryID.value = categoryId

      await execInstructors()

      if (instructorsError.value) {
         showError(instructorsError.value as ErrorT)
      } else if (instructorsData.value) {
         // console.log(`  instructorsData = ${JSON.stringify(instructorsData.value)}`)
         total.value = (instructorsData.value as { total: number }).total
         tempInstructors.value = (instructorsData.value as { data: User[] }).data
      }
      isPending.value = false
   }

   const subscriptionsURL = computed(() => `${config.public.API_URL}/api/category/director_sub/${categoryID.value ? categoryID.value : 0}`)
   const subscriptionsBody = computed(() => { user_array: instructors.value })

   const { data: subscriptionsData, error: subscriptionsError, execute: execSubscriptions } = useFetch(() => { return subscriptionsURL.value }, {
      method: 'POST',
      baseURL: config.public.API_URL,
      headers: headers,
      body: subscriptionsBody,
      immediate: false,
      watch: false,
   })

   async function saveSubscriptions(categoryId: number) {
      console.log(`saveSubscriptions()`)
      isPending.value = true
      categoryID.value = categoryId

      // merge subscriptions and update instructors
      instructors.value.forEach((item) => {
         if (tempSubscriptions.value && tempSubscriptions.value.includes(item.id)) {
            item.subscribed = 1
         } else {
            item.subscribed = 0
         }
      })

      await execSubscriptions()

      if (subscriptionsError.value) {
         showError(subscriptionsError.value as ErrorT)
      } else if (subscriptionsData.value) {
         // console.log(`  subscriptionsData = ${JSON.stringify(subscriptionsData.value)}`)
         setSnackbar({
            type: `success`,
            text: `Instructor subscriptions successfully updated!`,
         })
      }
      isPending.value = false
   }

   function $reset() {
      instructors.value = []
      tempInstructors.value = []
      instructor.value = {} as User
      tempInstructor.value = {} as User
      subscriptions.value = []
      tempSubscriptions.value = []
      isValid.value = true
      isPending.value = false
      error.value = null
      total.value = 0
      cacheBuster.value = 0
   }

   return {
      instructors, tempInstructors, instructor, tempInstructor, subscriptions, tempSubscriptions, isValid, isPending, error, total, cacheBuster, parameters, fullName, params, fetchAllInstructors, fetchInstructors, saveSubscriptions, $reset
   }
})

if (import.meta.hot) {
   import.meta.hot.accept(acceptHMRUpdate(useInstructorStore, import.meta.hot))
}
