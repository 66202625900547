import { storeToRefs } from 'pinia'
import type { ErrorT, Question, Response } from '~/types'
import { useQuestionBankStore } from '~~/stores/question-bank'
import { useSnackbarStore } from '~/stores/snackbar'
import { useAuthStore } from '~/stores/auth'
import { useAPI } from '~~/composables/useAPI'

export const useQuestionStore = defineStore('questionStore', () => {
  const { setSnackbar } = useSnackbarStore()
  const { headers } = storeToRefs(useAuthStore())
  const questionBankStore = useQuestionBankStore()
  const config = useRuntimeConfig()
  const { showError } = useAPI()

  const questionGroup = ref('questions')
  const questionIDs = ref<number[]>([])
  const selectedQuestionIDs = ref<number[]>([])
  const tempSelectedQuestionIDs = ref<number[]>([])
  const availableBankQuestions = ref<Question[]>([])
  const selectedBankQuestions = ref<Question[]>([])
  const question = ref<Question>()
  const tempQuestion = ref<Question>()
  const questionType = ref(0)
  const historyQuestions = ref<Question[]>([])
  const activeQuestion = ref('')
  const focusedResponseID = ref(-1)
  const answerID = ref<number | null>()
  const answerIDs = ref<number[] | null>()
  const truefalseQuestions = ref<Question[]>([])
  const multiplechoiceQuestions = ref<Question[]>([])
  const completionQuestions = ref<Question[]>([])
  const isValid = ref(true)
  const isPending = ref(false)
  const error = ref(null)
  const cacheBuster = ref(0)
  const parameters = ref({
    page: 1,
    per_page: 1000,
    order_direction: '',
    groupBy: [],
    order_by: 'title',
    search: '',
    watch: [cacheBuster.value],
  })
  const params = computed(() => {
    return { ...parameters.value }
  })

  const selectedBanks = ref<{ [key: string]: number }>({})
  const paramsQC = computed(() => {
    return { ...parameters.value, ...selectedBanks.value }
  })

  const getSelectedBankQuestionsTotal = computed(() => {
    return selectedBankQuestions.value.length
  })

  const deleteID = ref(0)
  const deleteURL = computed(() => `${config.public.API_URL}/api/questions/${deleteID.value ? deleteID.value : 0}`)

  const {
    data: deleteData,
    error: deleteError,
    execute: execDelete,
  } = useFetch(
    () => {
      return deleteURL.value
    },
    {
      method: 'delete',
      baseURL: config.public.API_URL,
      headers: headers,
      immediate: false,
      watch: false,
    }
  )

  async function deleteQuestion(id: number) {
    console.log(`deleteQuestion(${id})`)
    isPending.value = true
    deleteID.value = id

    await execDelete()

    if (deleteError.value) {
      showError(deleteError.value as ErrorT)
    } else if (deleteData.value) {
      setSnackbar({
        type: `success`,
        text: 'Question successfully deleted.',
      })
      // console.log(`Question deleted!`)
    }
    isPending.value = false
  }

  const deletesObj = ref({})
  const deletesBody = computed(() => deletesObj.value)

  const deletesURL = computed(() => `${config.public.API_URL}/api/questions/bulk_destroy`)
  const {
    data: deletesData,
    error: deletesError,
    execute: execDeletes,
  } = useFetch(
    () => {
      return deletesURL.value
    },
    {
      method: 'POST',
      baseURL: config.public.API_URL,
      headers: headers,
      body: deletesBody,
      immediate: false,
      watch: false,
    }
  )

  async function deleteQuestions() {
    console.log(`deleteQuestions(${selectedQuestionIDs.value})`)
    isPending.value = true
    deletesObj.value = {
      question_list: selectedQuestionIDs.value,
    }

    await execDeletes()

    if (deletesError.value) {
      showError(deletesError.value as ErrorT)
    } else if (deletesData.value) {
      setSnackbar({
        type: `success`,
        text: 'Question(s) successfully deleted.',
      })
      selectedQuestionIDs.value = []
    }
    isPending.value = false
  }

  const tagID = ref(0)
  const tagAction = ref('')
  const qnIDs = ref<number[]>([])
  const qnBankID = ref(0)
  const bulkTagURL = computed(() => `${config.public.API_URL}/api/questions/bulktag`)
  const bulkTagBody = computed(() => ({ tag_id: tagID.value, action: tagAction.value, question_list: qnIDs.value, question_bank_id: qnBankID.value }))

  const {
    data: bulkTagData,
    error: bulkTagError,
    execute: execBulkTag,
  } = useFetch(
    () => {
      return bulkTagURL.value
    },
    {
      method: 'POST',
      baseURL: config.public.API_URL,
      headers: headers,
      body: bulkTagBody,
      immediate: false,
      watch: false,
    }
  )

  async function bulkTag(_tagID: number, _tagAction: string, _qnIDs: number[], _qnBankID: number) {
    console.log(`bulkTag()`)
    isPending.value = true
    tagID.value = _tagID
    tagAction.value = _tagAction
    qnIDs.value = _qnIDs
    qnBankID.value = _qnBankID

    console.log(`bulkTagBody = ${JSON.stringify(bulkTagBody.value)}`)

    await execBulkTag()

    if (bulkTagError.value) {
      showError(bulkTagError.value as ErrorT)
      isPending.value = false
      return false
    } else if (bulkTagData.value) {
      // console.log(`  ${JSON.stringify(bulkTagData.value)}`)
      setSnackbar({
        type: `success`,
        text: `Question Tag(s) successfully ${tagAction.value === 'add' ? 'added' : 'removed'}.`,
      })
      selectedQuestionIDs.value = []
      isPending.value = false
      return true
    }
  }

  const recoverID = ref(0)
  const recoverURL = computed(() => `${config.public.API_URL}/api/questions/recover/${recoverID.value ? recoverID.value : 0}`)

  const {
    data: recoverData,
    error: recoverError,
    execute: execRecover,
  } = useFetch(
    () => {
      return recoverURL.value
    },
    {
      method: 'PUT',
      baseURL: config.public.API_URL,
      headers: headers,
      immediate: false,
      watch: false,
    }
  )

  async function recoverQuestion(id: number) {
    console.log(`recoverQuestion(${id})`)
    isPending.value = true
    recoverID.value = id

    await execRecover()

    if (recoverError.value) {
      showError(recoverError.value as ErrorT)
    } else if (recoverData.value) {
      // console.log(`Question #${id} recovered!`)
    }
    isPending.value = false
  }

  async function recoverQuestions() {
    console.log(`recoverQuestions()`)
    isPending.value = true

    if (selectedBankQuestions.value.length) {
      await Promise.all(
        selectedBankQuestions.value.map(async (question) => {
          await recoverQuestion(question.id!)
        })
      )

      console.log(`${selectedBankQuestions.value.length} Question(s) recovered!`)
      setSnackbar({
        type: `success`,
        text: `${selectedBankQuestions.value.length} Question(s) recovered!`,
      })

      isPending.value = false
      selectedBankQuestions.value = []

      return true
    }
  }

  const {
    data: saveData,
    error: saveError,
    execute: execSave,
  } = useFetch(`${config.public.API_URL}/api/questions`, {
    method: 'POST',
    baseURL: config.public.API_URL,
    headers: headers,
    body: tempQuestion,
    immediate: false,
    watch: false,
  })

  async function saveQuestion(source: string, sourceID: number) {
    isPending.value = true

    if (tempQuestion.value && source === 'test') {
      tempQuestion.value.question_bank_id = 0
      tempQuestion.value.test_id = sourceID
      tempQuestion.value.sort_order = 0
    }
    console.log(`saveQuestion(${source}, ${sourceID}): tempQuestion = ${JSON.stringify(tempQuestion.value)}`)

    await execSave()

    if (saveError.value) {
      showError(saveError.value as ErrorT)
    } else if (saveData.value) {
      setSnackbar({
        type: `success`,
        text: `Question saved successfully!`,
      })
      console.log(`Question Created! - ${JSON.stringify(saveData.value)}`)

      if (tempQuestion.value && source === 'bank' && tempQuestion.value.question_bank_id) {
        await questionBankStore.fetchQuestionBankById(tempQuestion.value.question_bank_id)
        cacheBuster.value += 1
      }
    }
    isPending.value = false
  }

  const updateURL = computed(() => `${config.public.API_URL}/api/questions/${tempQuestion.value ? tempQuestion.value.id : 0}`)

  const {
    data: updateData,
    error: updateError,
    execute: execUpdate,
  } = useFetch(
    () => {
      return updateURL.value
    },
    {
      method: 'PUT',
      baseURL: config.public.API_URL,
      headers: headers,
      body: tempQuestion,
      immediate: false,
      watch: false,
    }
  )

  async function updateQuestion(source: string) {
    console.log(`updateQuestion(${source})`)

    if (tempQuestion.value) {
      isPending.value = true

      if (source === 'test') {
        tempQuestion.value.question_bank_id = 0
      }

      // console.log(`tempQuestion = ${JSON.stringify(tempQuestion.value)}`)

      await execUpdate()

      if (updateError.value) {
        showError(updateError.value as ErrorT)
      } else if (updateData.value) {
        setSnackbar({
          type: `success`,
          text: `Question updated successfully!`,
        })
        // console.log(`Question Updated! - ${JSON.stringify(updateData.value)}`)
        cacheBuster.value += 1

        if (source === 'bank' && tempQuestion.value && tempQuestion.value.question_bank_id) {
          await questionBankStore.fetchQuestionBankById(tempQuestion.value.question_bank_id)
        }
      }
      isPending.value = false
    }
  }

  const questionID = ref(0)
  const questionURL = computed(() => `${config.public.API_URL}/api/questions/${questionID.value ? questionID.value : 0}`)

  const {
    data: questionData,
    error: questionError,
    execute: execQuestion,
  } = useFetch(
    () => {
      return questionURL.value
    },
    {
      method: 'GET',
      baseURL: config.public.API_URL,
      headers: headers,
      immediate: false,
      watch: false,
    }
  )

  async function fetchQuestion(id: number): Promise<boolean> {
    console.log(`fetchQuestion(${id})`)
    let tempQn
    isPending.value = true
    questionID.value = id

    await execQuestion()

    if (questionError.value) {
      showError(questionError.value as ErrorT)
      isPending.value = false
      return false
    } else if (questionData.value) {
      // console.log(`  questionData = ${questionData.value}`)
      tempQn = questionData.value as Question

      // console.log(`  tempQn = ${JSON.stringify(tempQn)}`)
      await setTempQuestion(tempQn)
      await setActiveQuestion(tempQn.id!)
    }
    isPending.value = false
    return true
  }

  async function setTempQuestion(data: Question) {
    // console.log(`setTempQuestion(${JSON.stringify(data)})`)
    tempQuestion.value = data

    const answer = tempQuestion.value.responses?.find((response) => response.fraction && response.fraction > 0)
    const answers = tempQuestion.value.responses?.filter((response) => response.fraction && response.fraction > 0)

    if (answer) {
      answerID.value = answer.id
    }
    if (answers && answers.length) {
      answerIDs.value = answers.map((item: Response) => item.id!)
    }
  }

  async function setActiveQuestion(id: number) {
    activeQuestion.value = JSON.stringify(tempQuestion.value)
    // console.log(`setActiveQuestion(${id})`)
    // console.log(`setActiveQuestion(${id}): activeQuestion = ${JSON.stringify(activeQuestion.value)}`)

    return true
  }

  const historyID = ref(0)
  const historyURL = computed(() => `${config.public.API_URL}/api/questions/history/${historyID.value ? historyID.value : 0}`)

  const {
    data: historyData,
    error: historyError,
    execute: execHistory,
  } = useFetch(
    () => {
      return historyURL.value
    },
    {
      method: 'GET',
      baseURL: config.public.API_URL,
      headers: headers,
      params: params,
      immediate: false,
      watch: false,
    }
  )

  async function fetchHistory(id: number) {
    console.log(`\n fetchHistory(${id})`)
    isPending.value = true
    historyID.value = id
    parameters.value.per_page = 1000

    await execHistory()

    if (historyError.value) {
      showError(historyError.value as ErrorT)
    } else if (historyData.value) {
      historyQuestions.value = historyData.value as Question[]
      // console.log(`  historyQuestions = (${JSON.stringify(historyData.value)})`)
    }
    isPending.value = false
  }

  async function revertHistory(id: number) {
    console.log(`\n revertHistory(${id})`)
  }

  const importObj = ref({})
  const importBody = computed(() => importObj.value)

  const {
    data: importData,
    error: importError,
    execute: execImport,
  } = useFetch(`${config.public.API_URL}/api/question_import`, {
    method: 'POST',
    baseURL: config.public.API_URL,
    headers: headers,
    body: importBody,
    immediate: false,
    watch: false,
  })

  async function importQuestions(id: number, json: Question[]) {
    console.log(`importQuestions(${id})`)
    isPending.value = true

    importObj.value = {
      question_list: json,
      question_bank_id: id,
    }

    await execImport()

    if (importError.value) {
      showError(importError.value as ErrorT)
    } else if (importData.value) {
      setSnackbar({
        type: `success`,
        text: `Questions imported successfully!`,
      })
      console.log(`Questions imported! - ${JSON.stringify(importData.value)}`)
      await questionBankStore.fetchQuestionBankById(id)
    }
    isPending.value = false
  }

  const {
    data: countData,
    error: countError,
    execute: execCount,
  } = useFetch(`${config.public.API_URL}/api/question/question_type_count`, {
    method: 'GET',
    baseURL: config.public.API_URL,
    headers: headers,
    params: paramsQC,
    immediate: false,
    watch: false,
  })

  async function fetchQuestionCount() {
    console.log(`fetchQuestionCount()`)
    isPending.value = true

    selectedBanks.value = {}

    if (questionBankStore.selectedBankIDs.length) {
      questionBankStore.selectedBankIDs.forEach((id: number, index: number) => {
        selectedBanks.value[`question_bank_id[${index}]`] = id
      })
    } else return

    await execCount()

    if (countError.value) {
      showError(countError.value as ErrorT)
      isPending.value = false
      return false
    } else if (countData.value) {
      // console.log(`Question types = ${JSON.stringify(countData.value)}`)
      isPending.value = false
      return countData.value
    }
  }

  const randomObj = ref({})
  const randomBody = computed(() => randomObj.value)

  const {
    data: randomData,
    error: randomError,
    execute: execRandom,
  } = useFetch(`${config.public.API_URL}/api/random_questions`, {
    method: 'POST',
    baseURL: config.public.API_URL,
    headers: headers,
    body: randomBody,
    immediate: false,
    watch: false,
  })

  async function fetchRandomQuestions(types: object) {
    isPending.value = true

    randomObj.value = {
      types: types,
      exclude: tempSelectedQuestionIDs.value,
      question_bank_id: questionBankStore.selectedBankIDs,
    }

    console.log(`fetchRandomQuestions(): randomObj = ${JSON.stringify(randomObj.value)}`)

    await execRandom()

    if (randomError.value) {
      showError(randomError.value as ErrorT)
      isPending.value = false
      return false
    } else if (randomData.value) {
      console.log(`Random Questions = ${JSON.stringify(randomData.value)}`)
      isPending.value = false
      return randomData.value
    }
  }

  const errataID = ref(0)
  const errataURL = computed(() => `${config.public.API_URL}/api/question_errata/${errataID.value ? errataID.value : 0}`)
  const errataObj = ref({})
  const errataBody = computed(() => errataObj.value)

  const {
    data: errataData,
    error: errataError,
    execute: execErrata,
  } = useFetch(
    () => {
      return errataURL.value
    },
    {
      method: 'POST',
      baseURL: config.public.API_URL,
      headers: headers,
      body: errataBody,
      immediate: false,
      watch: false,
    }
  )

  async function Questionerrata(id: number, message: string) {
    isPending.value = true
    errataID.value = id
    errataObj.value = {
      message: message,
    }

    await execErrata()

    if (errataError.value) {
      showError(errataError.value as ErrorT)
    } else if (errataData.value) {
      setSnackbar({
        type: `success`,
        text: `Question Errata sent successfully to National!`,
      })
      console.log(`Question Errata! - ${JSON.stringify(errataData.value)}`)
    }
    isPending.value = false
  }

  function $reset() {
    questionGroup.value = 'questions'
    questionIDs.value = []
    selectedQuestionIDs.value = []
    tempSelectedQuestionIDs.value = []
    availableBankQuestions.value = []
    selectedBankQuestions.value = []
    question.value = {} as Question
    questionType.value = 0
    historyQuestions.value = []
    activeQuestion.value = ''
    focusedResponseID.value = -1
    tempQuestion.value = {} as Question
    answerID.value = null
    answerIDs.value = []
    truefalseQuestions.value = []
    multiplechoiceQuestions.value = []
    completionQuestions.value = []
    isValid.value = true
    isPending.value = false
    error.value = null
    cacheBuster.value = 0
  }

  return {
    questionGroup,
    questionIDs,
    selectedQuestionIDs,
    tempSelectedQuestionIDs,
    availableBankQuestions,
    selectedBankQuestions,
    question,
    questionType,
    historyQuestions,
    activeQuestion,
    focusedResponseID,
    tempQuestion,
    answerID,
    answerIDs,
    truefalseQuestions,
    multiplechoiceQuestions,
    completionQuestions,
    isValid,
    isPending,
    error,
    cacheBuster,
    parameters,
    getSelectedBankQuestionsTotal,
    params,
    deleteQuestion,
    deleteQuestions,
    bulkTag,
    recoverQuestion,
    recoverQuestions,
    saveQuestion,
    updateQuestion,
    fetchQuestion,
    setTempQuestion,
    setActiveQuestion,
    fetchHistory,
    revertHistory,
    importQuestions,
    fetchQuestionCount,
    fetchRandomQuestions,
    Questionerrata,
    $reset,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useQuestionStore, import.meta.hot))
}
