import { storeToRefs } from 'pinia'
import type { ErrorT, QuestionBank, Question, Category } from '~/types'
import { useSnackbarStore } from '~/stores/snackbar'
import { useAuthStore } from '~/stores/auth'
import { useFilterStore } from '~/stores/filter'
import { useNarrativeStore } from '~/stores/narrative'
import { useAPI } from '~~/composables/useAPI'

export const useQuestionBankStore = defineStore('questionBankStore', () => {
  const { setSnackbar } = useSnackbarStore()
  const { headers } = storeToRefs(useAuthStore())
  const { categoryFilters, questionTagFilters, questionTypeFilters } = storeToRefs(useFilterStore())
  const narrativeStore = useNarrativeStore()

  const config = useRuntimeConfig()
  const { showError } = useAPI()

  const allQuestionBanks = ref<QuestionBank[]>([])
  const allBankIDs = ref<number[]>([])
  const questionBanks = ref<QuestionBank[]>([])
  const questionBank = ref<QuestionBank>()
  const bankQuestions = ref<Question[]>([])
  const banksQuestions = ref<Question[]>([])
  const deletedQuestions = ref<Question[]>([])
  const tempQuestionBank = ref<QuestionBank>()
  const selectedBankIDs = ref<number[]>([])
  const selectedBanks = ref<QuestionBank[]>([])
  const selectedBank = ref<QuestionBank>()
  const isValid = ref(true)
  const isPending = ref(false)
  const total = ref(0)
  const last_page = ref(0)
  const cacheBuster = ref(0)

  const selectedCategoryFilters = ref<{ [key: string]: number }>({})
  const selectedTagFilters = ref<{ [key: string]: number }>({})
  const selectedTypeFilters = ref<{ [key: string]: number }>({})

  const parameters = ref({
    page: 1,
    current_page: 1,
    per_page: 10,
    order_direction: '',
    groupBy: [],
    order_by: 'title',
    search: '',
    watch: [cacheBuster.value],
  })
  const bankParameters = ref({
    page: 1,
    current_page: 1,
    per_page: 10,
    order_direction: '',
    groupBy: [],
    order_by: 'title',
    search: '',
    watch: [cacheBuster.value],
  })

  const params = computed(() => {
    return { ...parameters.value }
  })
  const paramsAll = computed(() => {
    return { ...parameters.value, per_page: 10000 }
  })
  const paramsCT = computed(() => {
    return { ...parameters.value, ...selectedCategoryFilters.value, ...selectedTagFilters.value }
  })
  const paramsTT = computed(() => {
    return { ...parameters.value, ...selectedTypeFilters.value, ...selectedTagFilters.value }
  })
  const paramsTTselect = computed(() => {
    return { ...bankParameters.value, ...selectedTypeFilters.value, ...selectedTagFilters.value }
  })
  const paramsCTT = computed(() => {
    return { ...parameters.value, ...selectedCategoryFilters.value, ...selectedTypeFilters.value, ...selectedTagFilters.value }
  })

  const bankValidations = ref({
    title: true,
    category_id: true,
  })

  const selectedBanksTotal = computed(() => {
    return selectedBanks.value.length
  })

  const getBankQuestionTotal = computed(() => {
    return bankQuestions.value.length
  })

  const allBankQuestionIDs = computed(() => {
    return bankQuestions.value.map((item) => item.id)
  })

  function addQuestions(data: Question[]) {
    bankQuestions.value.push(...data)
  }

  function setBankQuestions(data: Question[]) {
    console.log(`setBankQuestions()`)
    bankQuestions.value = [...data]
    console.log(`  isRef(bankQuestions) = ${isRef(bankQuestions)}, type = ${typeof bankQuestions}`)
    console.log(`  bankQuestions.value.length = ${bankQuestions.value.length}`)
  }

  interface banksAPIResponse {
    current_page: number
    data: QuestionBank[]
    first_page_url: string
    from: number
    last_page: number
    last_page_url: string
    links: any[]
    next_page_url: string
    path: string
    per_page: string
    prev_page_url: any
    to: number
    total: number
  }

  const {
    data: allBanksData,
    error: allBanksError,
    execute: execAllBanks,
  } = useFetch(`${config.public.API_URL}/api/questionbanks`, {
    method: 'GET',
    baseURL: config.public.API_URL,
    headers: headers,
    params: paramsAll,
    immediate: false,
    watch: false,
  })

  async function fetchAllQuestionBanks() {
    if (isPending.value) return

    console.log(`fetchAllQuestionBanks()`)
    isPending.value = true

    await execAllBanks()

    if (allBanksError.value) {
      showError(allBanksError.value as ErrorT)
    } else if (allBanksData.value) {
      // console.log(`  allQuestionBanks = ${JSON.stringify(allBanksData.value.data)}`)
      const bankData = allBanksData.value as banksAPIResponse
      allQuestionBanks.value = bankData.data as unknown as QuestionBank[]

      total.value = bankData.total

      allBankIDs.value = allQuestionBanks.value.map((bank: QuestionBank) => bank.id) as number[]
    }
    isPending.value = false
  }

  const {
    data: banksData,
    error: banksError,
    execute: execBanks,
  } = useFetch(`${config.public.API_URL}/api/questionbanks`, {
    method: 'GET',
    baseURL: config.public.API_URL,
    headers: headers,
    params: paramsCT,
    immediate: false,
    watch: false,
  })

  async function fetchQuestionBanks() {
    if (isPending.value) return

    console.log(`fetchQuestionBanks()`)
    isPending.value = true

    selectedCategoryFilters.value = {}
    selectedTagFilters.value = {}

    if (categoryFilters.value.length) {
      categoryFilters.value.forEach((category: number, index: number) => {
        selectedCategoryFilters.value[`selected_categories[${index}]`] = category
      })
    }

    if (questionTagFilters.value.length) {
      questionTagFilters.value.forEach((tag: number, index: number) => {
        selectedTagFilters.value[`tags[${index}]`] = tag
      })
    }

    await execBanks()

    if (banksError.value) {
      showError(banksError.value as ErrorT)
    } else if (banksData.value) {
      // console.log(`  questionBanks success`)
      // console.log(`  questionBanks = ${JSON.stringify(banksData.value)}`)
      const bankData = banksData.value as banksAPIResponse
      questionBanks.value = bankData.data as unknown as QuestionBank[]

      total.value = bankData.total
    }
    isPending.value = false
  }

  const bankID = ref(0)
  const bankURL = computed(() => `${config.public.API_URL}/api/questionbanks/${bankID.value ? bankID.value : 0}`)

  interface bankAPIResponse {
    id: number
    title: string
    subtitle: string
    category_id: number
    default_theme_id: any
    user_id: number
    created: string
    modified: string
    status: number
    publish: number
    questions: QuestionsResponse
  }
  interface QuestionsResponse {
    current_page: number
    data: Question[]
    first_page_url: string
    from: number
    last_page: number
    last_page_url: string
    links: any[]
    next_page_url: any
    path: string
    per_page: string
    prev_page_url: any
    to: number
    total: number
  }

  const {
    data: bankData,
    error: bankError,
    execute: execBank,
  } = useFetch(
    () => {
      return bankURL.value
    },
    {
      method: 'GET',
      baseURL: config.public.API_URL,
      headers: headers,
      params: paramsTT,
      immediate: false,
      watch: false,
    }
  )

  const fetchQuestionBankById = async (id: number) => {
    if (isPending.value) return

    console.log(`\nfetchQuestionBankById(${id})`)
    isPending.value = true
    bankID.value = id

    selectedTypeFilters.value = {}
    selectedTagFilters.value = {}

    if (questionTypeFilters.value.length) {
      questionTypeFilters.value.forEach((type: number, index: number) => {
        selectedTypeFilters.value[`question_types[${index}]`] = type
      })
    }

    if (questionTagFilters.value.length) {
      questionTagFilters.value.forEach((tag: number, index: number) => {
        selectedTagFilters.value[`tags[${index}]`] = tag
      })
    }

    await execBank()

    if (bankError.value) {
      showError(bankError.value as ErrorT)
    } else if (bankData.value) {
      // console.log(`bankData = ${JSON.stringify(bankData.value)})`)
      const bankDataResponse = bankData.value as bankAPIResponse
      questionBank.value = bankDataResponse as unknown as QuestionBank

      // questionBank.value = { ...bankData.value }
      total.value = bankDataResponse.questions.total
      last_page.value = bankDataResponse.questions.last_page

      const bankID = questionBank.value.id ? questionBank.value.id : 0
      // console.log(`  narrativeBankID = ${bankID}`)

      narrativeStore.setNarrativeBankID(bankID)
      bankQuestions.value = bankDataResponse.questions && bankDataResponse.questions.data ? [...bankDataResponse.questions.data] : []
    }
    isPending.value = false
  }

  const {
    data: bankDataSelect,
    error: bankErrorSelect,
    execute: execBankSelect,
  } = useFetch(
    () => {
      return bankURL.value
    },
    {
      method: 'GET',
      baseURL: config.public.API_URL,
      headers: headers,
      params: paramsTTselect,
      immediate: false,
      watch: false,
    }
  )

  const fetchQuestionBankByIdSelect = async (id: number) => {
    if (isPending.value) return

    console.log(`\nfetchQuestionBankByIdSelect(${id})`)
    isPending.value = true
    bankID.value = id

    selectedTypeFilters.value = {}
    selectedTagFilters.value = {}

    if (questionTypeFilters.value.length) {
      questionTypeFilters.value.forEach((type: number, index: number) => {
        selectedTypeFilters.value[`question_types[${index}]`] = type
      })
    }

    if (questionTagFilters.value.length) {
      questionTagFilters.value.forEach((tag: number, index: number) => {
        selectedTagFilters.value[`tags[${index}]`] = tag
      })
    }

    await execBankSelect()

    if (bankErrorSelect.value) {
      showError(bankErrorSelect.value as ErrorT)
    } else if (bankDataSelect.value) {
      // console.log(`bankData = ${JSON.stringify(bankData.value)})`)
      const bankDataResponse = bankDataSelect.value as bankAPIResponse
      questionBank.value = bankDataResponse as unknown as QuestionBank

      total.value = bankDataResponse.questions.total
      last_page.value = bankDataResponse.questions.last_page

      const bankID = bankDataResponse.id ? bankDataResponse.id : 0
      // console.log(`  narrativeBankID = ${bankID}`)

      narrativeStore.setNarrativeBankID(bankID)
      bankQuestions.value = bankDataResponse.questions && bankDataResponse.questions.data ? [...bankDataResponse.questions.data] : []
    }
    isPending.value = false
  }

  // question_search
  const bankSearchID = ref(0)
  const bankSearchURL = computed(() => `${config.public.API_URL}/api/question_search/${bankSearchID.value ? bankSearchID.value : 0}`)
  const bankSearchQuery = ref({})
  const bankSearchBody = computed(() => bankSearchQuery.value)

  const {
    data: bankSearchData,
    error: bankSearchError,
    execute: execBankSearch,
  } = useFetch(
    () => {
      return bankSearchURL.value
    },
    {
      method: 'POST',
      baseURL: config.public.API_URL,
      headers: headers,
      params: paramsTT,
      body: bankSearchBody,
      immediate: false,
      watch: false,
    }
  )

  const searchQuestionBankById = async (id: number, queries: object) => {
    if (isPending.value) return

    console.log(`\nsearchQuestionBankById(${id}, ${JSON.stringify(queries)})`)
    isPending.value = true
    bankSearchID.value = id
    bankSearchQuery.value = { advanced_options: queries }

    selectedTypeFilters.value = {}
    selectedTagFilters.value = {}

    if (questionTypeFilters.value.length) {
      questionTypeFilters.value.forEach((type: number, index: number) => {
        selectedTypeFilters.value[`question_types[${index}]`] = type
      })
    }

    if (questionTagFilters.value.length) {
      questionTagFilters.value.forEach((tag: number, index: number) => {
        selectedTagFilters.value[`tags[${index}]`] = tag
      })
    }

    console.log(`\n selectedTypeFilters = ${selectedTypeFilters.value}, selectedTagFilters = ${selectedTagFilters.value}, bankSearchQuery = ${JSON.stringify(bankSearchQuery.value)}, bankSearchBody = ${JSON.stringify(bankSearchBody.value)}`)

    await execBankSearch()

    if (bankSearchError.value) {
      showError(bankSearchError.value as ErrorT)
    } else if (bankSearchData.value) {
      console.log(`  bankSearchData = ${JSON.stringify(bankSearchData.value)}`)
      const bankDataResponse = bankSearchData.value as bankAPIResponse
      questionBank.value = bankDataResponse as unknown as QuestionBank

      total.value = bankDataResponse.questions.total
      last_page.value = bankDataResponse.questions.last_page

      const bankID = bankDataResponse.id ? bankDataResponse.id : 0
      console.log(`  narrativeBankID = ${bankID}`)

      narrativeStore.setNarrativeBankID(bankID)
      bankQuestions.value = bankDataResponse.questions && bankDataResponse.questions.data ? [...bankDataResponse.questions.data] : []
    }
    isPending.value = false
  }

  // advanced_question_search
  const banksSearchURL = computed(() => `${config.public.API_URL}/api/advanced_question_search/0`)
  const banksSearchQuery = ref({})
  const banksSearchBody = computed(() => banksSearchQuery.value)

  interface banksSearchResponse {
    current_page: number
    data: banksSearchResponseData[]
    first_page_url: string
    from: number
    last_page: number
    last_page_url: string
    links: any[]
    next_page_url: string
    path: string
    per_page: string
    prev_page_url: any
    to: number
    total: number
  }

  interface banksSearchResponseData {
    id: number
    question_bank_id: number
    type_id: number
    explanation: any
    difficulty: any
    shuffle_answers: any
    answer_numbering: any
    reference: any
    notes: any
    created: string
    status: number
    main_branch: number
    parent_id: number
    iteration: number
    version_name: any
    history_json: string
    user_id: number
    general_feedback: any
    root_question_id: number
    narrative_id: any
    location: any
    objective: any
    stateStandard: any
    topic: any
    nationalObjective: any
    columns: number
    text: string
    plain_text: any
    original_date: string
    responses: Response[]
    questionbank: QuestionBank
    tags: any[]
    narrative: any
  }
  const {
    data: banksSearchData,
    error: banksSearchError,
    execute: execBanksSearch,
  } = useFetch(
    () => {
      return banksSearchURL.value
    },
    {
      method: 'POST',
      baseURL: config.public.API_URL,
      headers: headers,
      params: paramsCTT,
      body: banksSearchBody,
      immediate: false,
      watch: false,
    }
  )

  const searchQuestionBanks = async (queries: object) => {
    if (isPending.value) return

    console.log(`\nsearchQuestionBanks(${JSON.stringify(queries)})`)
    isPending.value = true
    banksSearchQuery.value = { advanced_options: queries }

    selectedCategoryFilters.value = {}
    selectedTypeFilters.value = {}
    selectedTagFilters.value = {}

    if (categoryFilters.value.length) {
      categoryFilters.value.forEach((category: number, index: number) => {
        selectedCategoryFilters.value[`selected_categories[${index}]`] = category
      })
    }

    if (questionTypeFilters.value.length) {
      questionTypeFilters.value.forEach((type: number, index: number) => {
        selectedTypeFilters.value[`question_types[${index}]`] = type
      })
    }

    if (questionTagFilters.value.length) {
      questionTagFilters.value.forEach((tag: number, index: number) => {
        selectedTagFilters.value[`tags[${index}]`] = tag
      })
    }

    console.log(`\n selectedCategoryFilters = ${selectedCategoryFilters.value}, selectedTypeFilters = ${selectedTypeFilters.value}, selectedTagFilters = ${selectedTagFilters.value}, banksSearchQuery = ${JSON.stringify(banksSearchQuery.value)}, banksSearchBody = ${JSON.stringify(banksSearchBody.value)}`)

    await execBanksSearch()

    if (banksSearchError.value) {
      showError(banksSearchError.value as ErrorT)
    } else if (banksSearchData.value) {
      console.log(`  banksSearchData = ${JSON.stringify(banksSearchData.value)}`)
      const bankSearchResponse = banksSearchData.value as banksSearchResponse

      total.value = bankSearchResponse.total
      last_page.value = bankSearchResponse.last_page

      banksQuestions.value = bankSearchResponse.data ? [...(bankSearchResponse.data as unknown as Question[])] : []
    }
    isPending.value = false
  }

  const {
    data: bankSearchDataSelect,
    error: bankSearchErrorSelect,
    execute: execBankSearchSelect,
  } = useFetch(
    () => {
      return bankSearchURL.value
    },
    {
      method: 'POST',
      baseURL: config.public.API_URL,
      headers: headers,
      params: paramsTTselect,
      body: bankSearchBody,
      immediate: false,
      watch: false,
    }
  )

  const searchQuestionBankByIdSelect = async (id: number, queries: object) => {
    if (isPending.value) return

    console.log(`\nsearchQuestionBankByIdSelect(${id}, ${JSON.stringify(queries)})`)
    isPending.value = true
    bankSearchID.value = id
    bankSearchQuery.value = { advanced_options: queries }

    selectedTypeFilters.value = {}
    selectedTagFilters.value = {}

    if (questionTypeFilters.value.length) {
      questionTypeFilters.value.forEach((type: number, index: number) => {
        selectedTypeFilters.value[`question_types[${index}]`] = type
      })
    }

    if (questionTagFilters.value.length) {
      questionTagFilters.value.forEach((tag: number, index: number) => {
        selectedTagFilters.value[`tags[${index}]`] = tag
      })
    }

    console.log(`\n selectedTypeFilters = ${selectedTypeFilters.value}, selectedTagFilters = ${selectedTagFilters.value}, bankSearchQuery = ${JSON.stringify(bankSearchQuery.value)}, bankSearchBody = ${JSON.stringify(bankSearchBody.value)}`)

    await execBankSearchSelect()

    if (bankSearchErrorSelect.value) {
      showError(bankSearchErrorSelect.value as ErrorT)
    } else if (bankSearchDataSelect.value) {
      console.log(`  bankSearchDataSelect = ${JSON.stringify(bankSearchDataSelect.value)}`)
      const bankDataResponse = bankSearchDataSelect.value as bankAPIResponse
      questionBank.value = bankDataResponse as unknown as QuestionBank

      total.value = bankDataResponse.questions.total
      last_page.value = bankDataResponse.questions.last_page

      const bankID = bankDataResponse.id ? bankDataResponse.id : 0
      console.log(`  narrativeBankID = ${bankID}`)

      narrativeStore.setNarrativeBankID(bankID)
      bankQuestions.value = bankDataResponse.questions && bankDataResponse.questions.data ? [...bankDataResponse.questions.data] : []
    }
    isPending.value = false
  }

  const deletedID = ref(0)
  const deletedURL = computed(() => `${config.public.API_URL}/api/questionbanks/show_deleted/${deletedID.value ? deletedID.value : 0}`)

  const {
    data: deletedData,
    error: deletedError,
    execute: execDeleted,
  } = useFetch(
    () => {
      return deletedURL.value
    },
    {
      method: 'GET',
      baseURL: config.public.API_URL,
      headers: headers,
      params: params,
      immediate: false,
      watch: false,
    }
  )

  async function fetchDeletedQuestionsByBankId(id: number) {
    console.log(`fetchDeletedQuestionsByBankId(${id})`)
    isPending.value = true
    deletedID.value = id

    await execDeleted()

    if (deletedError.value) {
      showError(deletedError.value as ErrorT)
    } else if (deletedData.value) {
      // console.log(`DeletedQuestions = ${JSON.stringify(deletedData.value.data)}`)
      const deletedResponse = deletedData.value as QuestionsResponse
      deletedQuestions.value = deletedResponse.data as unknown as Question[]
      total.value = deletedResponse.total
      last_page.value = deletedResponse.last_page
    }
    isPending.value = false
  }

  interface SuccessResponse {
    success: boolean
    message: string | number
  }

  const {
    data: createData,
    error: createError,
    execute: execCreate,
  } = useFetch(`${config.public.API_URL}/api/questionbanks`, {
    method: 'POST',
    baseURL: config.public.API_URL,
    headers: headers,
    body: questionBank,
    immediate: false,
    watch: false,
  })

  async function createQuestionBank() {
    console.log(`createQuestionBank()`)
    isPending.value = true

    await execCreate()

    if (createError.value) {
      if (createError.value.data.message === 'Duplicate Entry') {
        setSnackbar({
          type: `error`,
          text: `Question Bank Already Exists`,
        })
      } else {
        showError(createError.value as ErrorT)
      }
      isPending.value = false

      return false
    } else if (createData.value) {
      const response = createData.value as SuccessResponse

      setSnackbar({
        type: `success`,
        text: `New Question Bank (${response.message}) successfully created!`,
      })
      // console.log(`Question Bank Created! - ${JSON.stringify(response)}`)
      isPending.value = false

      navigateTo({ path: '/question-banks/' + response.message + '/edit' })
      return true
    }
  }

  const updateID = ref(0)
  const updateURL = computed(() => `${config.public.API_URL}/api/questionbanks/${updateID.value ? updateID.value : 0}`)

  const {
    data: updateData,
    error: updateError,
    execute: execUpdate,
  } = useFetch(
    () => {
      return updateURL.value
    },
    {
      method: 'PUT',
      baseURL: config.public.API_URL,
      headers: headers,
      body: questionBank,
      immediate: false,
      watch: false,
    }
  )

  async function updateQuestionBank(id: number) {
    questionBank.value = { ...tempQuestionBank.value }
    isPending.value = true
    updateID.value = id
    console.log(`updateQuestionBank(): updateID = ${updateID.value}`)

    await execUpdate()

    if (updateError.value) {
      if (updateError.value.data.message === 'Duplicate Entry') {
        setSnackbar({
          type: `error`,
          text: `Question Bank Already Exists`,
        })
      } else {
        showError(updateError.value as ErrorT)
      }

      isPending.value = false
      return false
    } else if (updateData.value) {
      setSnackbar({
        type: `success`,
        text: `Question Bank updated successfully!`,
      })

      isPending.value = false
      return true
    }
  }

  const {
    data: deleteData,
    error: deleteError,
    execute: execDelete,
  } = useFetch(
    () => {
      return bankURL.value
    },
    {
      method: 'delete',
      baseURL: config.public.API_URL,
      headers: headers,
      immediate: false,
      watch: false,
    }
  )

  async function deleteQuestionBank(id: number) {
    console.log(`deleteQuestionBank(${id})`)
    isPending.value = true
    bankID.value = id

    await execDelete()

    if (deleteError.value) {
      showError(deleteError.value as ErrorT)
    } else if (deleteData.value) {
      console.log(`Question Bank deleted!`)
      setSnackbar({
        type: `success`,
        text: 'Question Bank successfully deleted.',
      })
    }
    isPending.value = false
  }

  const toggleID = ref(0)
  const toggleURL = computed(() => `${config.public.API_URL}/api/questionbanks/publish_toggle/${toggleID.value ? toggleID.value : 0}`)

  const {
    data: toggleData,
    error: toggleError,
    execute: execToggle,
  } = useFetch(
    () => {
      return toggleURL.value
    },
    {
      method: 'POST',
      baseURL: config.public.API_URL,
      headers: headers,
      body: questionBank,
      immediate: false,
      watch: false,
    }
  )

  async function togglePublish(id: number) {
    console.log(`togglePublish(${id})`)
    isPending.value = true
    toggleID.value = id

    await execToggle()

    if (toggleError.value) {
      showError(toggleError.value as ErrorT)
    } else if (toggleData.value) {
      setSnackbar({
        type: `success`,
        text: `Question Bank has been updated!`,
      })
      // console.log(`Question Bank Updated! - ${JSON.stringify(toggleData.value)}`)
    }
    isPending.value = false
  }

  function resetQuestionBank() {
    // console.log(`resetQuestionBank()`)
    isPending.value = false
    isValid.value = false
    questionBank.value = {} as QuestionBank
  }

  function resetParameters() {
    // console.log(`resetParameters()`)
    parameters.value.page = 1
    parameters.value.current_page = 1
    // parameters.value.per_page = 5
    parameters.value.order_direction = ''
    parameters.value.search = ''
  }

  function $reset() {
    allQuestionBanks.value = []
    allBankIDs.value = []
    questionBanks.value = []
    questionBank.value = {} as QuestionBank
    tempQuestionBank.value = {} as QuestionBank
    bankQuestions.value = []
    banksQuestions.value = []
    deletedQuestions.value = []
    selectedBankIDs.value = []
    selectedBanks.value = []
    selectedBank.value = {} as QuestionBank
    isValid.value = true
    isPending.value = false
    total.value = 0
    cacheBuster.value = 0
  }

  return {
    allQuestionBanks,
    allBankIDs,
    questionBanks,
    questionBank,
    bankQuestions,
    banksQuestions,
    deletedQuestions,
    tempQuestionBank,
    selectedBankIDs,
    selectedBanks,
    selectedBank,
    isValid,
    isPending,
    total,
    last_page,
    cacheBuster,
    parameters,
    bankValidations,
    selectedBanksTotal,
    getBankQuestionTotal,
    allBankQuestionIDs,
    params,
    bankParameters,
    addQuestions,
    setBankQuestions,
    fetchAllQuestionBanks,
    fetchQuestionBanks,
    fetchQuestionBankById,
    fetchQuestionBankByIdSelect,
    searchQuestionBankById,
    searchQuestionBanks,
    searchQuestionBankByIdSelect,
    fetchDeletedQuestionsByBankId,
    createQuestionBank,
    updateQuestionBank,
    deleteQuestionBank,
    togglePublish,
    resetQuestionBank,
    resetParameters,
    $reset,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useQuestionBankStore, import.meta.hot))
}
