import type { Page } from '~/types'
import { useUserStore } from '~/stores/user'

export const usePageStore = defineStore('pageStore', () => {
   const { user } = storeToRefs(useUserStore())

   const pages = ref([
      {
         title: 'Home',
         icon: 'mdi-home',
         to: '/',
         roles: [1, 2, 3, 4, 5]
      },
      {
         title: 'Categories',
         icon: 'mdi-layers-triple',
         to: '/categories',
         roles: [1, 2, 3, 4]
      },
      {
         title: 'Question Banks',
         icon: 'mdi-archive',
         to: '/question-banks',
         roles: [1, 2, 3, 4, 5]
      },
      {
         title: 'Tests',
         icon: 'mdi-list-box',
         to: '/tests',
         roles: [1, 2, 3, 4, 5]
      },
      {
         title: 'Cleanup',
         icon: 'mdi-layers-triple-outline',
         to: '/cleanup',
         roles: [2]
      },
      {
         title: 'Tags',
         icon: 'mdi-tag',
         to: '/tags',
         roles: [1, 2, 3, 4, 5]
      },
      {
         title: 'Chapters',
         icon: 'mdi-office-building',
         to: '/chapters',
         roles: [1]
      },
      {
         title: 'Reports',
         icon: 'mdi-chart-box',
         to: '/reports',
         roles: [0]
      },
      {
         title: 'Notifications',
         icon: 'mdi-bell',
         to: '/notifications',
         roles: [1, 2, 3, 4, 5]
      },
      {
         title: 'Users',
         icon: 'mdi-account-group',
         to: '/users',
         roles: [1, 3]
      },
      {
         title: 'Support',
         icon: 'mdi-lifebuoy',
         to: '/support',
         roles: [1, 2, 3, 4, 5]
      },
   ])
   const page = ref(null)
   const rootPage = ref(null)
   const pageName = ref<string[]>([])

   const userPages = computed(() => {
      if (user.value) { return pages.value.filter((page) => page.roles.includes(user.value!.role_id)) }
      console.log(`userPages = ${JSON.stringify(pages.value.filter((page) => page.roles.includes(user.value!.role_id)))}`)
   })

   function $reset() {
      page.value = null
      pageName.value = []
   }

   return { pages, page, rootPage, pageName, userPages, $reset }
},
   {
      persist: {
         storage: piniaPluginPersistedstate.localStorage(),
      }
   }
)

if (import.meta.hot) {
   import.meta.hot.accept(acceptHMRUpdate(usePageStore, import.meta.hot))
}
