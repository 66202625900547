import { createVuetify } from 'vuetify'
import { iecLightTheme } from '~~/themes/themes'

export default defineNuxtPlugin((nuxtApp) => {
  const vuetify = createVuetify({
    components: {},
    theme: {
      defaultTheme: 'iecLightTheme',
      variations: {
        colors: ['primary', 'secondary', 'success', 'error', 'warning', 'brand-primary', 'brand-secondary', 'brand-grey', 'brand-blue-grey'],
        lighten: 3,
        darken: 3,
      },
      themes: {
        iecLightTheme,
      },
    },
    display: {
      mobileBreakpoint: 'sm',
      // thresholds: {
      //       xs: 0,
      //       sm: 340,
      //       md: 540,
      //       lg: 800,
      //       xl: 1280,
      // },
    },
    defaults: {
      global: {
        ripple: true,
      },
      VSheet: {
        elevation: 4,
      },
      VDataTable: {
        fixedHeader: true,
        noDataText: 'No Data available',
      },
    },
  })
  nuxtApp.vueApp.use(vuetify)
})
